import { useState } from "react";
import { createRoot } from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import { SWRConfig } from "swr";
import { ApiError, OpenAPI } from "./api";
import Root from "./root";
import "./styles.ts";

OpenAPI.BASE = "";
let loginRequestQueue: (() => void)[] = [];

function App(): React.ReactElement {
  const [loginExpired, setLoginExpired] = useState(false);

  return (
    <BrowserRouter>
      <SWRConfig
        value={{
          onError: (error) => {
            if (error instanceof ApiError) {
              if (error.status === 401) {
                setLoginExpired(true);
              }
            }
          },
          onErrorRetry: (error, _key, config, revalidate, { retryCount }) => {
            if (error instanceof ApiError && error.status === 401) {
              loginRequestQueue.push(() => revalidate({ retryCount }));
            } else {
              const maxRetryCount = config.errorRetryCount;

              // Exponential backoff
              const timeout =
                ~~(
                  (Math.random() + 0.5) *
                  (1 << (retryCount < 8 ? retryCount : 8))
                ) * config.errorRetryInterval;

              if (maxRetryCount !== undefined && retryCount > maxRetryCount) {
                return;
              }

              setTimeout(revalidate, timeout, { retryCount });
            }
          },
        }}
      >
        <Root
          loginExpired={loginExpired}
          onLoginRenewed={() => {
            for (const cb of loginRequestQueue) {
              cb();
            }

            loginRequestQueue = [];
            setLoginExpired(false);
          }}
        />
      </SWRConfig>
    </BrowserRouter>
  );
}

const container = document.createElement("div");
document.body.appendChild(container);
createRoot(container).render(<App />);
