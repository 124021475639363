/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { DesignerPage } from "../models/DesignerPage";
import type { DesignerPageListItem } from "../models/DesignerPageListItem";
import type { DesignerPageValues } from "../models/DesignerPageValues";

import type { CancelablePromise } from "../core/CancelablePromise";
import { OpenAPI } from "../core/OpenAPI";
import { request as __request } from "../core/request";

export class DesignerService {
    /**
     * List Designer Pages
     * @returns DesignerPageListItem Successful Response
     * @throws ApiError
     */
    public static listDesignerPages(): CancelablePromise<
        Array<DesignerPageListItem>
    > {
        return __request(OpenAPI, {
            method: "GET",
            url: "/api/designer_page",
        });
    }

    /**
     * Create Designer Page
     * @param requestBody
     * @returns number Successful Response
     * @throws ApiError
     */
    public static createDesignerPage(
        requestBody: DesignerPageValues,
    ): CancelablePromise<number> {
        return __request(OpenAPI, {
            method: "POST",
            url: "/api/designer_page",
            body: requestBody,
            mediaType: "application/json",
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Designer Page
     * @param id
     * @returns DesignerPage Successful Response
     * @throws ApiError
     */
    public static getDesignerPage(id: number): CancelablePromise<DesignerPage> {
        return __request(OpenAPI, {
            method: "GET",
            url: "/api/designer_page/{id}",
            path: {
                id: id,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Update Designer Page
     * @param id
     * @param requestBody
     * @returns any Successful Response
     * @throws ApiError
     */
    public static updateDesignerPage(
        id: number,
        requestBody: DesignerPageValues,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: "POST",
            url: "/api/designer_page/{id}",
            path: {
                id: id,
            },
            body: requestBody,
            mediaType: "application/json",
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Delete Designer Page
     * @param id
     * @returns any Successful Response
     * @throws ApiError
     */
    public static deleteDesignerPage(id: number): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: "DELETE",
            url: "/api/designer_page/{id}",
            path: {
                id: id,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Make Issue
     * @param title
     * @param calId
     * @param start
     * @param end
     * @returns any Successful Response
     * @throws ApiError
     */
    public static makeIssue(
        title: string,
        calId: number,
        start: string,
        end: string,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: "GET",
            url: "/api/buddelbreef/generate",
            query: {
                title: title,
                cal_id: calId,
                start: start,
                end: end,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Make Preview
     * @param calId
     * @param start
     * @param end
     * @returns any Successful Response
     * @throws ApiError
     */
    public static makePreview(
        calId: number,
        start: string,
        end: string,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: "GET",
            url: "/api/buddelbreef/preview",
            query: {
                cal_id: calId,
                start: start,
                end: end,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
}
