let default_font, dict_leftmin, dict_rightmin, pdf_pt_per_mm;
// from dataclasses import dataclass
// from typing import Dict, List, Optional
// from fpdf import FPDF
// from fpdf.drawing import Transform
// from fpdf.enums import PathPaintRule
// from pyphen import Pyphen
// from designer import RichTextNode
// from fonts import weights
// from linebreaker import Linebreaker, TextNode, ImageNode, BreakNode, ContentBox, GlueBox, PenaltyBox
// Imports and shims
import { PathPaintRule, Transform, weights } from "./canvas-shim.ts";
import {
    BreakNode,
    ContentBox,
    GlueBox,
    ImageNode,
    Linebreaker,
    PenaltyBox,
    TextNode,
} from "./linebreaker.js";

export { LayoutEngine, dict_leftmin, dict_rightmin, pdf_pt_per_mm };
dict_leftmin = 2;
dict_rightmin = 2;
pdf_pt_per_mm = 2.83465;
default_font = "NotoSans";
class PDFImageNode extends ImageNode {
    constructor(id, w, h) {
        super();
        this.image_id = id;
        this.width = w;
        this.height = h;
    }
}
class PDFPiktoNode extends ImageNode {
    constructor(name, w, h) {
        super();
        this.name = name;
        this.width = w;
        this.height = h;
    }
}
class TextState {
    constructor(values = {}) {
        this.x = 0;
        this.y = 0;
        this.size = 12;
        this.line_height = 1.3;
        this.font_name = default_font;
        this.weight = 400;
        this.italic = false;
        this.pendingMargin = 0;
        this.width = 0;
        Object.assign(this, values);
        this.__dict__ = this;
    }
    // x: Name(id='float', ctx=Load())
    // y: Name(id='float', ctx=Load())
    // size: Name(id='float', ctx=Load())
    // line_height: Name(id='float', ctx=Load())
    // font_name: Name(id='str', ctx=Load())
    // weight: Name(id='int', ctx=Load())
    // italic: Name(id='bool', ctx=Load())
    // pendingMargin: Name(id='float', ctx=Load())
    // width: Name(id='float', ctx=Load())
}
function inlines2nodes(inlines, engine) {
    let weight,
        italic,
        child,
        _,
        w,
        nodes,
        h,
        width,
        state,
        image_id,
        name,
        mark,
        height,
        font;
    state = engine._text_state;
    nodes = [];
    for (child of inlines) {
        if (child.type === "text") {
            weight = state.weight;
            italic = state.italic;
            if (child.marks !== null && child.marks !== undefined) {
                for (mark of child.marks) {
                    if (mark.type === "strong") {
                        weight = 700;
                    } else if (mark.type === "em") {
                        italic = true;
                    }
                }
            }
            font = weights[state.font_name][weight][italic ? 1 : 0];
            nodes.push(
                new TextNode({
                    text: child.text,
                    font: font,
                    size: state.size,
                    height: state.size * state.line_height,
                    marks: child.marks,
                }),
            );
        } else if (child.type === "image") {
            if ("image_id" in child.attrs) {
                width = child.attrs["width"] ?? 0;
                height = child.attrs["height"] ?? 0;
                image_id = child.attrs["image_id"] ?? -1;
                nodes.push(new PDFImageNode(image_id, width, height));
            } else if ("pikto" in child.attrs) {
                name = child.attrs["pikto"] ?? "film";
                [_, w, h] = engine._piktos[name];
                nodes.push(new PDFPiktoNode(name, w, h));
            }
        } else if (child.type === "hard_break") {
            nodes.push(new BreakNode());
        } else {
            throw new Error(`Unknown inline type ${child.type}`);
        }
    }
    return nodes;
}
class LayoutEngine {
    // _pdf: Subscript(value=Name(id='Optional', ctx=Load()), slice=Name(id='FPDF', ctx=Load()), ctx=Load())
    // _text_state: Name(id='TextState', ctx=Load())
    // _images: Subscript(value=Name(id='Dict', ctx=Load()), slice=Tuple(elts=[Name(id='int', ctx=Load()), Name(id='str', ctx=Load())], ctx=Load()), ctx=Load())
    // _piktos: Subscript(value=Name(id='dict', ctx=Load()), slice=Tuple(elts=[Name(id='str', ctx=Load()), Subscript(value=Name(id='tuple', ctx=Load()), slice=Tuple(elts=[Name(id='str', ctx=Load()), Name(id='int', ctx=Load()), Name(id='int', ctx=Load())], ctx=Load()), ctx=Load())], ctx=Load()), ctx=Load())
    // last_font: Subscript(value=Name(id='Optional', ctx=Load()), slice=Name(id='str', ctx=Load()), ctx=Load())
    // last_size: Name(id='float', ctx=Load())
    constructor(hypher, piktos) {
        this._linebreaker = new Linebreaker(hypher);
        this._text_state = new TextState();
        this._piktos = piktos;
    }
    linebreak(content, width, state) {
        let box,
            last_node,
            line,
            line_height,
            line_nodes,
            lines,
            nodes,
            queued,
            result;
        this._text_state = state;
        nodes = inlines2nodes(content, this);
        console.log(nodes);
        result = [];
        lines = this._linebreaker.justify(nodes, width);
        for (line of lines) {
            line_nodes = [];
            line_height = 0;
            last_node = null;
            queued = "";
            for (box of line) {
                if (box instanceof ContentBox) {
                    if (box.content.height > line_height) {
                        line_height = box.content.height;
                    }
                    if (
                        box.content instanceof TextNode &&
                        box.piece !== null &&
                        box.piece !== undefined
                    ) {
                        if (last_node === null || last_node === undefined) {
                            last_node = box.content;
                            queued = box.piece;
                        } else if (last_node === box.content) {
                            queued += box.piece;
                        } else {
                            line_nodes.push(
                                new RichTextNode({
                                    type: "text",
                                    marks: last_node.marks,
                                    text: queued,
                                }),
                            );
                            last_node = box.content;
                            queued = box.piece;
                        }
                    } else if (box.content instanceof PDFImageNode) {
                        if (last_node) {
                            line_nodes.push(
                                new RichTextNode({
                                    type: "text",
                                    marks: last_node.marks,
                                    text: queued,
                                }),
                            );
                            last_node = null;
                            queued = "";
                        }
                        line_nodes.push(
                            new RichTextNode({
                                type: "image",
                                attrs: {
                                    image_id: box.content.image_id,
                                    width: box.content.width,
                                    height: box.content.height,
                                },
                            }),
                        );
                    } else if (box.content instanceof PDFPiktoNode) {
                        if (last_node) {
                            line_nodes.push(
                                new RichTextNode({
                                    type: "text",
                                    marks: last_node.marks,
                                    text: queued,
                                }),
                            );
                            last_node = null;
                            queued = "";
                        }
                        line_nodes.push(
                            new RichTextNode({
                                type: "image",
                                attrs: { pikto: box.content.name },
                            }),
                        );
                    }
                } else if (box instanceof GlueBox) {
                    if (last_node) {
                        queued += " ";
                    } else {
                        line_nodes.push(
                            new RichTextNode({ type: "text", text: " " }),
                        );
                    }
                } else if (box instanceof PenaltyBox) {
                    if (box.content instanceof BreakNode) {
                        if (last_node) {
                            line_nodes.push(
                                new RichTextNode({
                                    type: "text",
                                    marks: last_node.marks,
                                    text: queued,
                                }),
                            );
                            last_node = null;
                            queued = "";
                        }
                        line_nodes.push(
                            new RichTextNode({ type: "hard_break" }),
                        );
                    } else if (box.flagged) {
                        if (last_node) {
                            queued += "\u00ad";
                        } else {
                            line_nodes.push(
                                new RichTextNode({
                                    type: "text",
                                    text: "\u00ad",
                                }),
                            );
                        }
                    }
                }
            }
            if (last_node) {
                line_nodes.push(
                    new RichTextNode({
                        type: "text",
                        marks: last_node.marks,
                        text: queued,
                    }),
                );
            }
            result.push([line_height, line_nodes]);
        }
        return result;
    }
    reset(pdf, x, y, width, line_height = 1.3) {
        this._pdf = pdf;
        this.last_font = null;
        this.last_size = 0;
        this._text_state = new TextState({
            x: x,
            y: y,
            width: x + width,
            weight: 400,
            line_height: line_height,
        });
    }
    get_y() {
        return this._text_state.y;
    }
    load_images(images) {
        this._images = images;
    }
    render_image(image_id, width, height) {
        if (this._pdf !== null && this._pdf !== undefined) {
            this._pdf.image(
                this._images[image_id],
                this._text_state.x,
                this._text_state.y,
                width,
                height,
            );
        }
    }
    render_children(node) {
        let child;
        if (node.content === null || node.content === undefined) {
            return;
        }
        for (let i = 0; i < node.content.length; i++) {
            child = node.content[i];
            this.render_rich_text(child, i);
        }
    }
    render_inlines(inlines) {
        let layout, lh, line, lines, lnode, lnode_y, nh, nodes, state;
        state = this._text_state;
        if (state.pendingMargin > 0) {
            state.y += state.pendingMargin;
            state.pendingMargin = 0;
        }
        nodes = inlines2nodes(inlines, this);
        lines = this._linebreaker.justify(nodes, state.width - state.x);
        layout = this._linebreaker.layout(lines, state.width - state.x);
        lh = 0;
        for (line of layout) {
            lh = 0;
            for (lnode of line) {
                nh = lnode.height ?? 0;
                if (nh > lh) {
                    lh = nh;
                }
            }
            for (lnode of line) {
                lnode_y = state.y + lh - (lnode.height ?? 0);
                if (this._pdf !== null && this._pdf !== undefined) {
                    if (lnode instanceof PDFImageNode) {
                        this._pdf.image(
                            this._images[lnode.image_id],
                            lnode.x + state.x,
                            lnode_y,
                            lnode.width,
                            lnode.height,
                        );
                    } else if (lnode instanceof PDFPiktoNode) {
                        this._pdf.image(
                            this._piktos[lnode.name][0],
                            lnode.x + state.x,
                            lnode_y,
                            lnode.width,
                            lnode.height,
                        );
                    } else if (lnode instanceof TextNode) {
                        if (
                            this.last_font !== lnode.font ||
                            this.last_size !== lnode.size
                        ) {
                            this.last_font = lnode.font;
                            this.last_size = lnode.size;
                            this._pdf.set_font(
                                this.last_font,
                                "",
                                this.last_size,
                            );
                        }
                        this._pdf.text(
                            lnode.x + state.x,
                            lnode_y + state.size * 0.9,
                            lnode.text,
                        );
                    }
                }
            }
            state.y += lh;
        }
        state.y -= lh;
    }
    render_rich_text(node, idx = -1) {
        let child,
            last_font,
            last_size,
            level,
            pre_x,
            pre_y,
            prev_lh,
            prev_size,
            prev_weight,
            state;
        state = this._text_state;
        if (node.type === "doc") {
            pre_x = state.x;
            pre_y = state.y;
            this.render_children(node);
            if (false && this._pdf !== null && this._pdf !== undefined) {
                this._pdf.set_draw_color(255, 0, 0);
                this._pdf.set_fill_color(0, 255, 0);
                this._pdf.set_line_width(2);
                console.log("rect", [
                    pre_x,
                    pre_y,
                    state.width,
                    state.y - pre_y,
                    "D",
                ]);
                this._pdf.rect(pre_x, pre_y, state.width, state.y - pre_y, "D");
            }
        } else if (node.type === "paragraph") {
            if (idx !== 0) {
                state.pendingMargin = Math.max(
                    state.pendingMargin,
                    state.size * 1.25,
                );
            }
            pre_x = state.x;
            pre_y = state.y;
            if (
                node.content === null ||
                node.content === undefined ||
                node.content.length < 1
            ) {
            } else {
                last_font = state.font_name;
                last_size = state.size;
                if ("family" in node.attrs) {
                    state.font_name = node.attrs["family"];
                }
                if ((node.attrs["size"] ?? "").endsWith("pt")) {
                    state.size = node.attrs["size"].slice(0, -2);
                }
                this.render_inlines(node.content);
                state.font_name = last_font;
                state.size = last_size;
            }
            state.x = pre_x;
            state.pendingMargin = state.size * 0.0025;
        } else if (node.type === "heading") {
            level = 1;
            if (node.attrs !== null && node.attrs !== undefined) {
                level = node.attrs["level"] ?? 1;
            }
            prev_size = state.size;
            prev_weight = state.weight;
            prev_lh = state.line_height;
            if (level === 1) {
                state.size = 12 * 2.25;
                state.line_height = 1.1111111;
            } else {
                state.size = 12 * 1.5;
                state.pendingMargin = Math.max(
                    state.pendingMargin,
                    state.size * 2,
                );
                state.line_height = 1.3333333;
            }
            state.weight = 700;
            this.render_inlines(node.content);
            state.y += state.size * state.line_height;
            if (level === 1) {
                state.pendingMargin = state.size * 0.8888889;
            } else {
                state.pendingMargin = state.size;
            }
            state.size = prev_size;
            state.weight = prev_weight;
            state.line_height = prev_lh;
        } else if (node.type === "bullet_list") {
            state.x += 1.625 * state.size;
            state.y += state.pendingMargin;
            state.pendingMargin = 0;
            for (child of node.content) {
                if (child.type !== "list_item") {
                    throw new Error(
                        `Unexpected child type ${child.type} in bullet list`,
                    );
                }
                if (this._pdf !== null && this._pdf !== undefined) {
                    {
                        const $$ctx = this._pdf.new_path({
                            paint_rule: PathPaintRule.STROKE,
                        });
                        let path = $$ctx.__enter__();
                        try {
                            path.auto_close = false;
                            path.transform = Transform.translation(
                                state.x - 1.625 * state.size - 2,
                                state.y,
                            );
                            path.move_to(13.5, 7.5);
                            path.line_to(6, 15);
                            path.line_to(3, 11);
                            $$ctx.__exit__();
                        } catch ($$e) {
                            $$ctx.__exit__($$e);
                            throw $$e;
                        }
                    }
                }
                state.y += 0.1 * state.size;
                this.render_children(child);
                state.y += state.size * 0.8;
                state.pendingMargin = 0;
            }
            state.y -= state.size;
            state.x -= 1.625 * state.size;
            state.pendingMargin = 0;
        }
    }
}
