import {
  Button,
  ButtonSet,
  Column,
  FlexGrid,
  InlineNotification,
  Row,
  Stack,
  StructuredListBody,
  StructuredListCell,
  StructuredListHead,
  StructuredListRow,
  StructuredListWrapper,
} from "@carbon/react";
import { useRef, useState } from "react";
import useSWR from "swr";
import { DestinationService } from "../api";
import { Form, FormHandles, TextArea, TextInput } from "../lib/form";

interface FormState {
  id?: number;
  title: string;
  description: string;
}

export default function PublishDestinationsList(): React.ReactElement {
  const [id, setID] = useState<number | null>(null);
  const [saving, setSaving] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const form = useRef<FormHandles>(null);

  const { data, mutate } = useSWR("publish-destinations", (_) =>
    DestinationService.listPublishDestinations(),
  );

  async function save(values: FormState) {
    setSaving(true);
    setError(null);

    if (id) {
      try {
        await DestinationService.updatePublishDestination(id, values);
      } catch (e) {
        const msg = e instanceof Error ? e.message : String(e);
        setError(`Konnte Kalender nicht speichern: ${msg}`);
      }
    } else {
      try {
        const result = await DestinationService.createPublishDestination(
          values.title,
          values.description,
        );
        setID(result.id);
      } catch (e) {
        const msg = e instanceof Error ? e.message : String(e);
        setError(`Konnte Kalender nicht anlegen: ${msg}`);
      }
    }

    setSaving(false);
    void mutate();
  }

  function reset() {
    form.current?.reset();
    setID(null);
    setError(null);
  }

  async function remove() {
    setSaving(true);
    setError(null);

    if (id) {
      try {
        await DestinationService.deletePublishDestination(id);
        reset();
        void mutate();
      } catch (e) {
        const msg = e instanceof Error ? e.message : String(e);
        setError(`Konnte Veröffentlichungsziel nicht löschen: ${msg}`);
      }
    }

    setSaving(false);
  }

  return (
    <FlexGrid>
      <Row>
        <Column md={2}>
          <StructuredListWrapper selection isCondensed>
            <StructuredListHead>
              <StructuredListRow head>
                <StructuredListCell head>Name</StructuredListCell>
              </StructuredListRow>
            </StructuredListHead>
            <StructuredListBody>
              {data?.map((row) => (
                <StructuredListRow key={row.id}>
                  <StructuredListCell
                    onClick={() => {
                      form.current?.setData(row);
                      setID(row.id);
                    }}
                  >
                    {row.title}
                  </StructuredListCell>
                </StructuredListRow>
              ))}
            </StructuredListBody>
          </StructuredListWrapper>
        </Column>
        <Column md={6}>
          <Form ref={form} onSubmit={(values: FormState) => void save(values)}>
            <Stack gap={7}>
              {error ? (
                <InlineNotification kind="error" title="Fehler">
                  {error}
                </InlineNotification>
              ) : null}

              <TextInput id="title" labelText="Name" />
              <TextArea id="description" rows={5} labelText="Beschreibung" />
              <ButtonSet>
                <Button kind="primary" type="submit" disabled={saving}>
                  {saving ? "Bitte warten..." : id ? "Speichern" : "Anlegen"}
                </Button>
                <Button kind="secondary" onClick={reset}>
                  Abbrechen
                </Button>
                <Button
                  kind="danger"
                  disabled={saving || !id}
                  onClick={() => void remove()}
                >
                  {saving ? "Bitte warten..." : "Löschen"}
                </Button>
              </ButtonSet>
            </Stack>
          </Form>
        </Column>
      </Row>
    </FlexGrid>
  );
}
