import {
  baseKeymap,
  chainCommands,
  exitCode,
  joinDown,
  joinUp,
  lift,
  selectParentNode,
  setBlockType,
  toggleMark,
} from "prosemirror-commands";
import { history, redo, undo } from "prosemirror-history";
import {
  ellipsis,
  emDash,
  inputRules,
  smartQuotes,
  textblockTypeInputRule,
  undoInputRule,
  wrappingInputRule,
} from "prosemirror-inputrules";
import { keymap } from "prosemirror-keymap";
import {
  Dropdown,
  MenuElement,
  MenuItem,
  MenuItemSpec,
  blockTypeItem,
  joinUpItem,
  liftItem,
  menuBar,
  redoItem,
  undoItem,
} from "prosemirror-menu";
import { DOMSerializer, MarkType, Node } from "prosemirror-model";
import {
  liftListItem,
  sinkListItem,
  splitListItem,
  wrapInList,
} from "prosemirror-schema-list";
import { EditorState, Transaction } from "prosemirror-state";
import { EditorView } from "prosemirror-view";
import "prosemirror-view/style/prosemirror.css";
import { useEffect, useRef } from "react";
import ReactDOM from "react-dom/client";
import IconBold from "~icons/ic/round-format-bold";
import IconItalic from "~icons/ic/round-format-italic";
import IconList from "~icons/ic/round-format-list-bulleted";
import pdfSchema from "./rich-text-schema";
import "./rich-text.css";

export { pdfSchema };

export const serializer = DOMSerializer.fromSchema(pdfSchema);

type Command = (
  state: EditorState,
  dispatch?: (tr: Transaction) => void,
) => boolean;
const mac =
  typeof navigator !== "undefined"
    ? /Mac|iP(hone|[oa]d)/.test(navigator.platform)
    : false;

function buildKeymap(mapKeys?: { [key: string]: false | string }) {
  const keys: { [key: string]: Command } = {};

  let type;
  function bind(key: string, cmd: Command): void {
    if (mapKeys) {
      const mapped = mapKeys[key];
      if (mapped === false) {
        return;
      }
      if (mapped) {
        key = mapped;
      }
    }
    keys[key] = cmd;
  }

  bind("Mod-z", undo);
  bind("Shift-Mod-z", redo);
  bind("Backspace", undoInputRule);
  if (!mac) {
    bind("Mod-y", redo);
  }

  bind("Alt-ArrowUp", joinUp);
  bind("Alt-ArrowDown", joinDown);
  bind("Mod-BracketLeft", lift);
  bind("Escape", selectParentNode);

  if ((type = pdfSchema.marks.strong)) {
    bind("Mod-b", toggleMark(type));
    bind("Mod-B", toggleMark(type));
  }
  if ((type = pdfSchema.marks.em)) {
    bind("Mod-i", toggleMark(type));
    bind("Mod-I", toggleMark(type));
  }

  if ((type = pdfSchema.nodes.bullet_list)) {
    bind("Shift-Ctrl-8", wrapInList(type));
  }
  if ((type = pdfSchema.nodes.ordered_list)) {
    bind("Shift-Ctrl-9", wrapInList(type));
  }
  if ((type = pdfSchema.nodes.hard_break)) {
    const br = type,
      cmd = chainCommands(exitCode, (state, dispatch) => {
        if (dispatch) {
          dispatch(state.tr.replaceSelectionWith(br.create()).scrollIntoView());
        }
        return true;
      });
    bind("Mod-Enter", cmd);
    bind("Shift-Enter", cmd);
    if (mac) {
      bind("Ctrl-Enter", cmd);
    }
  }
  if ((type = pdfSchema.nodes.list_item)) {
    bind("Enter", splitListItem(type));
    bind("Mod-[", liftListItem(type));
    bind("Mod-]", sinkListItem(type));
  }
  if ((type = pdfSchema.nodes.paragraph)) {
    bind("Shift-Ctrl-0", setBlockType(type));
  }
  if ((type = pdfSchema.nodes.heading)) {
    for (let i = 1; i <= 6; i++) {
      bind(`Shift-Ctrl-${i}`, setBlockType(type, { level: i }));
    }
  }
  if ((type = pdfSchema.nodes.horizontal_rule)) {
    const hr = type;
    bind("Mod-_", (state, dispatch) => {
      if (dispatch) {
        dispatch(state.tr.replaceSelectionWith(hr.create()).scrollIntoView());
      }
      return true;
    });
  }

  return keys;
}

function menuButton(icon: JSX.Element, options: MenuItemSpec): MenuItem {
  return new MenuItem({
    render: () => {
      const cont = document.createElement("div");
      ReactDOM.createRoot(cont).render(icon);
      return cont;
    },
    ...options,
  });
}

function markButton(icon: JSX.Element, mark: MarkType): MenuItem {
  return menuButton(icon, {
    run: toggleMark(mark),
    active: (state) => {
      if (state.selection.empty) {
        return !!mark.isInSet(
          state.storedMarks || state.selection.$from.marks(),
        );
      } else {
        return state.doc.rangeHasMark(
          state.selection.from,
          state.selection.to,
          mark,
        );
      }
    },
  });
}

interface RichTextEditorProps {
  defaultValue: { [key: string]: any } | null | undefined;
  onChange: (
    value: { [key: string]: any } | null | undefined,
    node: Node,
  ) => void;
}

export default function RichTextEditor(
  props: RichTextEditorProps,
): React.ReactElement {
  const el = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (!el.current) {
      return;
    }

    const rules = smartQuotes.concat(ellipsis, emDash);
    rules.push(
      wrappingInputRule(
        /^(\d+)\.\s$/,
        pdfSchema.nodes.ordered_list,
        (match) => ({ order: +match[1] }),
        (match, node) =>
          node.childCount + (node.attrs.order as number) === +match[1],
      ),
    );
    rules.push(
      wrappingInputRule(/^\s*([-+*])\s$/, pdfSchema.nodes.bullet_list),
    );
    rules.push(
      textblockTypeInputRule(
        /^(#{1,3})\s$/,
        pdfSchema.nodes.heading,
        (match) => ({ level: match[1].length }),
      ),
    );

    const plugins = [
      inputRules({ rules }),
      keymap(buildKeymap({})),
      keymap(baseKeymap),
      menuBar({
        floating: false,
        content: [
          [
            undoItem as unknown as MenuElement,
            redoItem as unknown as MenuElement,
            markButton(<IconBold />, pdfSchema.marks.strong),
            markButton(<IconItalic />, pdfSchema.marks.em),
            menuButton(<IconList />, {
              run: wrapInList(pdfSchema.nodes.bullet_list),
              select: (state: EditorState): boolean => {
                const $from = state.selection.$from;
                for (let d = $from.depth; d >= 0; d--) {
                  if ($from.node(d).type === pdfSchema.nodes.bullet_list) {
                    return false;
                  }
                }
                return true;
              },
            }),
            liftItem,
            joinUpItem,
            new Dropdown(
              [
                blockTypeItem(pdfSchema.nodes.paragraph, {
                  attrs: { family: "Adelbrook" },
                  label: "Fließtext",
                }),
                blockTypeItem(pdfSchema.nodes.paragraph, {
                  attrs: { family: "NotoSans" },
                  label: "North Fließtext",
                }),
                blockTypeItem(pdfSchema.nodes.heading, {
                  attrs: { level: 1 },
                  label: "Überschrift",
                }),
                blockTypeItem(pdfSchema.nodes.heading, {
                  attrs: { level: 2 },
                  label: "2. Überschrift",
                }),
              ],
              { label: "Format" },
            ) as unknown as MenuElement,
          ],
        ],
      }),
      history(),
    ];

    let changeTimeout: null | number = null;
    const view = new EditorView(el.current, {
      state: EditorState.create({
        doc: props.defaultValue
          ? pdfSchema.nodeFromJSON(props.defaultValue)
          : undefined,
        plugins,
        schema: pdfSchema,
      }),
      dispatchTransaction(tr) {
        if (changeTimeout !== null) {
          clearTimeout(changeTimeout);
        }

        const newState = view.state.apply(tr);
        view.updateState(newState);

        changeTimeout = setTimeout(
          () => props.onChange(view.state.doc.toJSON() as Node, view.state.doc),
          300,
        );
      },
    });

    return () => view.destroy();

    // We *really* don't want to re-initialize the editor if the props change.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <div className="bg-white p-2 ut-rich-text prose" ref={el} />;
}
