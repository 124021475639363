/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { PDFMetrics } from "../models/PDFMetrics";

import type { CancelablePromise } from "../core/CancelablePromise";
import { OpenAPI } from "../core/OpenAPI";
import { request as __request } from "../core/request";

export class PdfService {
    /**
     * Get Page Pdf
     * @param id
     * @returns any Successful Response
     * @throws ApiError
     */
    public static getPagePdf(id: number): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: "GET",
            url: "/api/designer_page/{id}/pdf",
            path: {
                id: id,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Metrics
     * @returns PDFMetrics Successful Response
     * @throws ApiError
     */
    public static getMetrics(): CancelablePromise<PDFMetrics> {
        return __request(OpenAPI, {
            method: "GET",
            url: "/api/pdf_metrics",
        });
    }
}
