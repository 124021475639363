/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import {
  Checkbox as CarbonCheckbox,
  CheckboxProps as CarbonCheckboxProps,
  DatePicker as CarbonDatePicker,
  DatePickerProps as CarbonDatePickerProps,
  Select as CarbonSelect,
  SelectProps as CarbonSelectProps,
  TextArea as CarbonTextArea,
  TextAreaProps as CarbonTextAreaProps,
  TextInput as CarbonTextInput,
  TextInputProps as CarbonTextInputProps,
  DatePickerInput,
  FormGroup,
  Stack,
} from "@carbon/react";
import { FormHandles, useField } from "@unform/core";
import { Form as UnformForm } from "@unform/web";
import { format } from "date-fns";
import React, { useEffect, useRef, useState } from "react";
import { useIMask } from "react-imask";

export type { FormHandles } from "@unform/core";

export const Form = React.forwardRef(function Form(
  props: React.ComponentPropsWithRef<typeof UnformForm>,
  ref: React.Ref<FormHandles>,
): React.ReactElement {
  return <UnformForm ref={ref} className="cds--form" {...props} />;
});

interface TextInputProps extends CarbonTextInputProps {
  mask?: IMask.AnyMaskedOptions;
}

export function TextInput(props: TextInputProps): React.ReactElement {
  const inputRef = useRef<HTMLInputElement>(null);
  const { fieldName, defaultValue, registerField, error } = useField(props.id);
  let mask: ReturnType<typeof useIMask> | null = null;

  if (props.mask) {
    // We only initialise the mask if the mask prop is set. As a result, it's not allowed to add or remove the prop
    // dynamically but we never do that anyway.
    // eslint-disable-next-line react-hooks/rules-of-hooks
    mask = useIMask(props.mask);
  }

  useEffect(() => {
    if (mask && inputRef.current) {
      mask.ref.current = inputRef.current;
    }

    registerField({
      name: fieldName,
      ref: inputRef,
      getValue(ref: React.RefObject<HTMLInputElement>) {
        return ref.current?.value ?? "";
      },
      setValue(ref: React.RefObject<HTMLInputElement>, value: string) {
        if (ref.current) {
          ref.current.value = value;
        }
      },
      clearValue(ref: React.RefObject<HTMLInputElement>) {
        if (ref.current) {
          ref.current.value = "";
        }
      },
    });
    // `mask` changes on every call but we don't want to re-run this effect when it does.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fieldName, registerField]);

  return (
    <CarbonTextInput
      ref={inputRef}
      {...props}
      defaultValue={defaultValue}
      warn={!!error}
      warnText={error}
    />
  );
}

interface CheckboxProps extends CarbonCheckboxProps {
  id: string;
}

export function Checkbox(props: CheckboxProps): React.ReactElement {
  // TODO: handle error
  const { fieldName, registerField } = useField(props.id);
  const formRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: formRef,
      getValue(ref: React.RefObject<HTMLInputElement>) {
        return !!ref.current?.checked;
      },
      setValue(ref: React.RefObject<HTMLInputElement>, value: boolean) {
        if (ref.current) {
          ref.current.checked = value;
        }
      },
      clearValue(ref: React.RefObject<HTMLInputElement>) {
        if (ref.current) {
          ref.current.checked = false;
        }
      },
    });
  }, [fieldName, registerField]);

  return <CarbonCheckbox ref={formRef} {...props} />;
}

interface CheckboxListProps {
  id: string;
  labelText: string;
  options: Record<string, string>;
}

export function CheckboxList(props: CheckboxListProps): React.ReactElement {
  const { fieldName, registerField, error } = useField(props.id);
  const boxes = useRef<HTMLInputElement[] | null>(null);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: null,
      getValue(_ref: React.RefObject<HTMLInputElement>) {
        const checked: string[] = [];
        if (boxes.current) {
          for (const el of boxes.current) {
            if (el.checked) {
              checked.push(el.value);
            }
          }
        }

        return checked;
      },
      setValue(_ref: React.RefObject<HTMLInputElement>, value: string[]) {
        if (boxes.current) {
          for (const el of boxes.current) {
            el.checked = value.indexOf(el.value) > -1;
          }
        }
      },
      clearValue(_ref: React.RefObject<HTMLInputElement>) {
        if (boxes.current) {
          for (const el of boxes.current) {
            el.checked = false;
          }
        }
      },
    });
  }, [fieldName, registerField]);

  boxes.current = [];
  return (
    <FormGroup
      legendText={props.labelText}
      message={!!error}
      messageText={error}
    >
      <Stack gap={7}>
        {Object.entries(props.options).map(([key, label]) => (
          <CarbonCheckbox
            ref={(cb) => (cb ? boxes.current?.push(cb) : null)}
            key={key}
            id={key}
            labelText={label}
            value={key}
          />
        ))}
      </Stack>
    </FormGroup>
  );
}

interface TextAreaProps extends CarbonTextAreaProps {
  id: string;
}

export function TextArea(props: TextAreaProps): React.ReactElement {
  const inputRef = useRef<HTMLTextAreaElement>(null);
  const { fieldName, defaultValue, registerField, error } = useField(props.id);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef,
      getValue(ref: React.RefObject<HTMLTextAreaElement>) {
        return ref.current?.value ?? "";
      },
      setValue(ref: React.RefObject<HTMLTextAreaElement>, value: string) {
        if (ref.current) {
          ref.current.value = value;
        }
      },
      clearValue(ref: React.RefObject<HTMLTextAreaElement>) {
        if (ref.current) {
          ref.current.value = "";
        }
      },
    });
  }, [fieldName, registerField]);

  return (
    <CarbonTextArea
      ref={inputRef}
      {...props}
      defaultValue={defaultValue}
      invalid={!!error}
      invalidText={error}
    />
  );
}

interface DatePickerProps extends CarbonDatePickerProps {
  id: string;
  labelText: string;
}

export function DatePicker(props: DatePickerProps): React.ReactElement {
  const { fieldName, defaultValue, registerField, error } = useField(props.id);
  const [value, setValue] = useState(defaultValue as Date | null);
  const savedValue = useRef<Date | null>(value);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: null,
      getValue() {
        return savedValue.current;
      },
      setValue(_ref: unknown, value: Date | null) {
        setValue(value);
        savedValue.current = value;
      },
      clearValue() {
        setValue(null);
        savedValue.current = null;
      },
    });
  }, [fieldName, registerField]);

  return (
    <CarbonDatePicker
      className={props.className}
      dateFormat="d.m.Y"
      datePickerType="single"
      onChange={(value) => {
        const v: Date = value[0];
        setValue(v);
        savedValue.current = v;
      }}
    >
      <DatePickerInput
        id={props.id}
        invalid={!!error}
        invalidText={error}
        placeholder="dd.mm.YYYY"
        pattern="\d{2}\.\d{2}\.\d{4}"
        type="text"
        labelText={props.labelText}
        value={value ? format(value, "dd.MM.yyyy") : ""}
      />
    </CarbonDatePicker>
  );
}

interface DateRangePickerProps extends CarbonDatePickerProps {
  id: string;
}

export function DateRangePicker(
  props: DateRangePickerProps,
): React.ReactElement {
  const { fieldName, defaultValue, registerField, error } = useField(props.id);
  const [value, setValue] = useState(
    defaultValue as [string | null, string | null],
  );
  const savedValue = useRef<[string | null, string | null]>(value);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: null,
      getValue() {
        return savedValue.current;
      },
      setValue(_ref: unknown, value: [string | null, string | null]) {
        setValue(value);
        savedValue.current = value;
      },
      clearValue() {
        setValue([null, null]);
        savedValue.current = [null, null];
      },
    });
  }, [fieldName, registerField]);

  return (
    <CarbonDatePicker
      dateFormat="d.m.Y"
      datePickerType="range"
      value={[value[0] ?? "", value[1] ?? ""]}
      onChange={(value) => {
        const v: [string | null, string | null] = [
          value[0].toISOString(),
          value[1].toISOString(),
        ];
        setValue(v);
        savedValue.current = v;
      }}
    >
      <DatePickerInput
        id={props.id + "-start"}
        pattern="\d{2}\.\d{2}\.\d{4}"
        placeholder="dd.mm.YYYY"
        labelText="Start"
        type="text"
        invalid={!!error}
        invalidText={error}
      />
      <DatePickerInput
        id={props.id + "-end"}
        pattern="\d{2}\.\d{2}\.\d{4}"
        placeholder="dd.mm.YYYY"
        labelText="Ende"
        type="text"
      />
    </CarbonDatePicker>
  );
}

interface SelectProps extends CarbonSelectProps {
  id: string;
}

export function Select(props: SelectProps): React.ReactElement {
  const { fieldName, defaultValue, registerField, error } = useField(props.id);
  const ref = useRef<HTMLSelectElement>(null);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: ref,
      getValue() {
        return ref.current?.value ?? "";
      },
      setValue(_ref: unknown, value: string) {
        if (ref.current) {
          ref.current.value = value;
        }
      },
      clearValue() {
        if (ref.current) {
          let defaultOption =
            ref.current.options.length > 0 ? ref.current.options[0] : null;
          for (const option of ref.current.options) {
            if (option.defaultSelected) {
              defaultOption = option;
              break;
            }
          }

          ref.current.value = defaultOption?.value ?? "";
        }
      },
    });
  }, [fieldName, registerField]);

  return (
    <CarbonSelect
      ref={ref}
      {...props}
      invalid={!!error}
      invalidText={error}
      defaultValue={defaultValue}
    />
  );
}
