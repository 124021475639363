import { Button, FileUploaderButton, InlineNotification } from "@carbon/react";
import { useField } from "@unform/core";
import { Draft, produce } from "immer";
import { nanoid } from "nanoid";
import { useEffect, useRef, useState } from "react";
import Moveable from "react-moveable";
import Selecto from "selecto";
import { ImageService, RichTextNode, UploadedImage } from "../../api";
import RichTextEditor from "../../lib/rich-text";
import { Canvas as RenderCanvas, getLayoutEngine, pxToPT } from "./canvas-shim";
import { LayoutEngine } from "./layout_engine";

export interface CanvasItem {
  type: "text" | "image";
  top: number;
  left: number;
  width: number;
  height: number;
  imageID: number;
  imageWidth: number;
  imageHeight: number;
  text: RichTextNode | undefined;
  image: string;
}

interface InternalCanvasItem extends CanvasItem {
  id: string;
  el: HTMLDivElement;
  zIndex: number;
}

async function processImageUpload(
  image: File,
  block: CanvasItem,
  img: HTMLImageElement,
): Promise<void> {
  const buffer = await image.arrayBuffer();
  const digest = await crypto.subtle.digest("SHA-256", buffer);
  const hash = Array.from(new Uint8Array(digest))
    .map((num) => num.toString(16).padStart(2, "0"))
    .join("");

  let meta: UploadedImage | null = null;
  try {
    const imgInfo = await ImageService.checkImage(hash);
    if (imgInfo.found && imgInfo.image) {
      meta = imgInfo.image;
    }
  } catch (e) {
    console.error(e);
  }

  if (!meta) {
    try {
      meta = await ImageService.uploadImage({ image });
    } catch (e) {
      console.error(e);
      return;
    }
  }

  block.imageWidth = meta.width;
  block.imageHeight = meta.height;
  block.imageID = meta.id;
  img.src = `/api/image/${meta.id}/image`;
}

export async function loadImages(
  doc: RichTextNode,
  renderer: LayoutEngine,
): Promise<void> {
  const ids = new Set<number>();
  let queue = [doc];

  while (true) {
    const node = queue.shift();
    if (!node) {
      break;
    }

    if (node.content) {
      queue = queue.concat(node.content);
    }

    const attrs = node.attrs as Record<string, unknown>;
    if (node.type === "image" && typeof attrs.image_id === "number") {
      ids.add(attrs.image_id);
    }
  }

  const images: Record<number, ImageBitmap> = {};
  await Promise.all(
    Array.from(ids).map(async (id: number): Promise<void> => {
      const response = await fetch(`/api/image/${id}/image`);
      const bitmap = await createImageBitmap(await response.blob());

      images[id] = bitmap;
    }),
  );

  renderer.load_images(images);
}

function addItem(
  items: React.RefObject<Record<string, InternalCanvasItem>>,
  container: React.RefObject<HTMLDivElement>,
  type: "text" | "image",
  image?: File,
) {
  if (!items.current) {
    throw new Error("Invalid items passed");
  }

  if (!container.current) {
    throw new Error("Invalid container passed");
  }

  const id = nanoid();
  const el = document.createElement("div");

  items.current[id] = {
    type,
    zIndex: Object.values(items.current).length,
    el,
    id,
    height: 100,
    width: 100,
    imageID: 0,
    imageWidth: 0,
    imageHeight: 0,
    top: 50,
    left: 50,
    text: undefined,
    image: "",
  };

  el.setAttribute("id", id);
  el.setAttribute(
    "class",
    "absolute dgn-item border border-transparent hover:border-blue-300 prose",
  );
  el.style.zIndex = String(items.current[id].zIndex);
  el.style.top = "50px";
  el.style.left = "50px";
  el.style.width = "100px";
  el.style.height = "100px";

  if (type === "text") {
    items.current[id].text = {
      type: "doc",
      content: [
        {
          type: "paragraph",
          content: [{ type: "text", text: "Text" }],
        },
      ],
    };

    const canvas = document.createElement("canvas");
    canvas.width = 100;
    canvas.height = 100;

    const ctx = canvas.getContext("2d");
    if (ctx) {
      ctx.font = "12pt Adelbrook";
      ctx.strokeText("Text", 0, 0);
    }

    el.appendChild(canvas);
  } else if (type === "image") {
    const img = document.createElement("img");

    if (image) {
      img.addEventListener("load", () => {
        el.style.width = `${img.width}px`;
        el.style.height = `${img.height}px`;

        if (items.current) {
          items.current[id].width = img.width;
          items.current[id].height = img.height;
        }
      });

      img.src = URL.createObjectURL(image);
      void processImageUpload(image, items.current[id], img);
    }

    el.appendChild(img);
  }

  const cover = document.createElement("div");
  cover.setAttribute("class", "absolute inset-0");
  el.appendChild(cover);

  container.current?.appendChild(el);
  return id;
}

async function loadItems(
  items: React.RefObject<Record<string, InternalCanvasItem>>,
  container: React.RefObject<HTMLDivElement>,
  newItems: CanvasItem[],
  engine?: LayoutEngine | null,
): Promise<void> {
  if (!items.current) {
    throw new Error("Invalid items passed");
  }

  if (!engine) {
    engine = await getLayoutEngine();
  }

  for (const newItem of newItems) {
    const id = addItem(items, container, newItem.type);
    Object.assign(items.current[id], newItem);

    const el = items.current[id].el;
    el.style.top = `${newItem.top}px`;
    el.style.left = `${newItem.left}px`;
    el.style.width = `${newItem.width}px`;
    el.style.height = `${newItem.height}px`;

    renderItem(items.current[id], el, engine);
  }
}

function renderItem(
  item: CanvasItem,
  el: HTMLDivElement,
  engine: LayoutEngine,
): void {
  if (item.type === "text") {
    const canvas = el.querySelector<HTMLCanvasElement>("canvas");
    if (!canvas) {
      return;
    }

    canvas.width = item.width + 2;
    canvas.height = item.height;

    const ctx = canvas.getContext("2d");
    if (!ctx || !item.text) {
      return;
    }

    try {
      engine.reset(new RenderCanvas(ctx), 0, 0, pxToPT(item.width));
      engine.render_rich_text(item.text, 0);
    } catch (e) {
      console.error(e, item.text, item.width);
    }
  } else if (item.type === "image") {
    const img = el.querySelector<HTMLImageElement>("img");
    if (!img) {
      return;
    }

    const url = `/api/image/${item.imageID}/image`;
    if (!img.src.endsWith(url)) {
      img.src = url;
    }
  }
}

function removeItems(
  items: React.RefObject<Record<string, InternalCanvasItem>>,
  keys: string[],
): void {
  if (!items.current) {
    return;
  }

  for (const key of keys) {
    if (items.current[key]) {
      const el = items.current[key].el;
      el.parentNode?.removeChild(el);
      delete items.current[key];
    }
  }
}

interface CanvasProps {
  name: string;
  width: number;
  height: number;
}

export function Canvas(props: CanvasProps): React.ReactElement {
  const container = useRef<HTMLDivElement | null>(null);
  const blocks = useRef<Record<string, InternalCanvasItem>>({});
  const [selection, setSelection] = useState<Record<string, HTMLDivElement>>(
    {},
  );
  const [selectedItem, setSelectedItem] = useState<string | null>(null);
  const [keepRatio, setKeepRatio] = useState(false);
  // can't change the return type of useField()
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const { fieldName, defaultValue, registerField, error } = useField(
    props.name,
  );
  const layoutEngine = useRef<LayoutEngine | null>(null);

  useEffect(() => {
    void (async () => {
      layoutEngine.current = await getLayoutEngine();
    })();
  }, []);

  useEffect(() => {
    const sel = new Selecto({
      container: container.current,
      selectableTargets: [".dgn-item"],
      selectFromInside: false,
      dragCondition: (e) => {
        const target = (e.inputEvent as Event).target;
        return (
          !target ||
          !(target instanceof HTMLElement) ||
          target.getAttribute("class")?.indexOf("moveable") === -1
        );
      },
    });

    sel.on("select", (e) => {
      setSelection(
        produce((selection) => {
          for (const el of e.added) {
            el.classList.remove("border-transparent");
            selection[el.getAttribute("id")!] =
              el as unknown as Draft<HTMLDivElement>;
          }

          for (const el of e.removed) {
            el.classList.add("border-transparent");

            delete selection[el.getAttribute("id")!];
          }

          const items = Object.entries(selection);
          if (items.length === 1) {
            setSelectedItem(items[0][0]);
            setKeepRatio(blocks.current[items[0][0]].type === "image");
          } else {
            setSelectedItem(null);
            setKeepRatio(false);
          }
        }),
      );
    });

    void loadItems(
      blocks,
      container,
      (defaultValue as CanvasItem[]) ?? [],
      layoutEngine.current,
    );
    registerField({
      name: fieldName,
      getValue(): CanvasItem[] {
        const sorted = Object.values(blocks.current);
        sorted.sort((a, b) => a.zIndex - b.zIndex);

        return produce(
          sorted as Draft<(CanvasItem & Partial<InternalCanvasItem>)[]>,
          (list) => {
            for (const item of list) {
              delete item.id;
              delete item.el;
              delete item.zIndex;
            }
          },
        );
      },
      setValue(ref, value: CanvasItem[]) {
        for (const item of Object.values(blocks.current)) {
          item.el.parentNode?.removeChild(item.el);
        }

        blocks.current = {};
        void loadItems(blocks, container, value, layoutEngine.current);

        // clear selection
        setSelectedItem(null);
        setSelection({});
      },
      clearValue() {
        for (const item of Object.values(blocks.current)) {
          item.el.parentNode?.removeChild(item.el);
        }

        blocks.current = {};

        // clear selection
        setSelectedItem(null);
        setSelection({});
      },
    });

    return () => sel.destroy();
  }, [defaultValue, fieldName, registerField]);

  function updateZIndex(diff: number): void {
    const selected = Object.keys(selection);
    const maxIndex = Object.values(blocks.current).length;

    for (const id of selected) {
      if (diff > 0 && blocks.current[id].zIndex + diff >= maxIndex) {
        return;
      } else if (diff < 0 && blocks.current[id].zIndex + diff < 0) {
        return;
      }
    }

    for (const block of Object.values(blocks.current)) {
      if (selected.indexOf(block.id) > -1) {
        block.zIndex += diff;
      } else {
        block.zIndex -= diff;
      }
      block.el.style.zIndex = String(block.zIndex);
    }
  }

  const selectedEls = Object.values(selection);
  return (
    <div>
      {error ? (
        <InlineNotification kind="error" title="Fehler">
          {error}
        </InlineNotification>
      ) : null}

      <Button
        kind="tertiary"
        size="sm"
        onClick={() => addItem(blocks, container, "text")}
      >
        Text
      </Button>
      <FileUploaderButton
        buttonKind="tertiary"
        size="sm"
        labelText="Bild"
        disableLabelChanges
        onChange={(e) => {
          if (e.target.files && e.target.files.length > 0) {
            for (let i = 0; i < e.target.files.length; i++) {
              const file = e.target.files.item(i);
              if (file) {
                addItem(blocks, container, "image", file);
              }
            }
          }
        }}
      />
      <Button
        kind="danger--ghost"
        size="sm"
        onClick={() => {
          removeItems(blocks, Object.keys(selection));
          setSelectedItem(null);
          setSelection({});
        }}
      >
        Löschen
      </Button>
      <Button kind="ghost" size="sm" onClick={() => updateZIndex(1)}>
        Hoch
      </Button>
      <Button kind="ghost" size="sm" onClick={() => updateZIndex(-1)}>
        Runter
      </Button>
      <div className="flex flex-row">
        <div
          ref={container}
          className="bg-white border border-gray-500 relative overflow-hidden"
          style={{
            width: `${props.width}px`,
            height: `${props.height}px`,
          }}
        >
          <Moveable
            bounds={{
              top: 0,
              left: 0,
              right: container.current?.clientWidth,
              bottom: container.current?.clientHeight,
            }}
            target={selectedEls}
            resizable
            draggable
            snappable
            snapGap
            isDisplaySnapDigit
            elementGuidelines={Object.values(blocks.current)
              .map((item) => item.el)
              .filter((el) => !selectedEls.includes(el))}
            keepRatio={keepRatio}
            throttleResize={1}
            renderDirections={["nw", "n", "ne", "w", "e", "sw", "s", "se"]}
            origin={false}
            onResize={(e) => {
              const item = blocks.current[Object.keys(selection)[0]];
              item.width = e.width;
              item.height = e.height;

              e.target.style.width = `${e.width}px`;
              e.target.style.height = `${e.height}px`;

              if (layoutEngine.current) {
                renderItem(
                  item,
                  e.target as HTMLDivElement,
                  layoutEngine.current,
                );
              }
            }}
            onResizeGroup={(e) => {
              const keys = Object.keys(selection);

              for (let i = 0; i < e.targets.length; i++) {
                const item = blocks.current[keys[i]];
                item.width = e.events[i].width;
                item.height = e.events[i].height;

                e.targets[i].style.width = `${e.events[i].width}px`;
                e.targets[i].style.height = `${e.events[i].height}px`;
              }
            }}
            onDrag={(e) => {
              const item = blocks.current[Object.keys(selection)[0]];
              item.left = e.left;
              item.top = e.top;

              e.target.style.left = `${e.left}px`;
              e.target.style.top = `${e.top}px`;
            }}
            onDragGroup={(e) => {
              const keys = Object.keys(selection);

              for (let i = 0; i < e.targets.length; i++) {
                const item = blocks.current[keys[i]];
                item.left = e.events[i].left;
                item.top = e.events[i].top;

                e.targets[i].style.left = `${e.events[i].left}px`;
                e.targets[i].style.top = `${e.events[i].top}px`;
              }
            }}
          />
        </div>
        <div className="flex-1">
          {selectedItem && blocks.current[selectedItem].type === "text" ? (
            <RichTextEditor
              key={selectedItem}
              defaultValue={blocks.current[selectedItem].text}
              onChange={(value, _node) => {
                const block = blocks.current[selectedItem];
                block.text = value as RichTextNode;

                if (layoutEngine.current) {
                  renderItem(
                    block,
                    selection[selectedItem],
                    layoutEngine.current,
                  );
                }
              }}
            />
          ) : null}
        </div>
      </div>
    </div>
  );
}
