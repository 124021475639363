/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { DitUnDat } from "../models/DitUnDat";
import type { DitUnDatMoveParams } from "../models/DitUnDatMoveParams";
import type { DitUnDatValues } from "../models/DitUnDatValues";

import type { CancelablePromise } from "../core/CancelablePromise";
import { OpenAPI } from "../core/OpenAPI";
import { request as __request } from "../core/request";

export class DitUnDatService {
    /**
     * List Dit Un Dat
     * @returns DitUnDat Successful Response
     * @throws ApiError
     */
    public static listDitUnDat(): CancelablePromise<Array<DitUnDat>> {
        return __request(OpenAPI, {
            method: "GET",
            url: "/api/dit_un_dat",
        });
    }

    /**
     * Create Dit Un Dat
     * @param requestBody
     * @returns DitUnDat Successful Response
     * @throws ApiError
     */
    public static createDitUnDat(
        requestBody?: DitUnDatValues,
    ): CancelablePromise<DitUnDat> {
        return __request(OpenAPI, {
            method: "POST",
            url: "/api/dit_un_dat",
            body: requestBody,
            mediaType: "application/json",
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Move Dit Un Dat
     * @param requestBody
     * @returns any Successful Response
     * @throws ApiError
     */
    public static moveDitUnDat(
        requestBody: DitUnDatMoveParams,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: "POST",
            url: "/api/dit_un_dat/move",
            body: requestBody,
            mediaType: "application/json",
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Dit Un Dat
     * @param id
     * @returns DitUnDat Successful Response
     * @throws ApiError
     */
    public static getDitUnDat(id: number): CancelablePromise<DitUnDat> {
        return __request(OpenAPI, {
            method: "GET",
            url: "/api/dit_un_dat/{id}",
            path: {
                id: id,
            },
            errors: {
                404: `Not Found`,
                422: `Validation Error`,
            },
        });
    }

    /**
     * Update Dit Un Dat
     * @param id
     * @param requestBody
     * @returns any Successful Response
     * @throws ApiError
     */
    public static updateDitUnDat(
        id: number,
        requestBody?: DitUnDatValues,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: "POST",
            url: "/api/dit_un_dat/{id}",
            path: {
                id: id,
            },
            body: requestBody,
            mediaType: "application/json",
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Delete Dit Un Dat
     * @param id
     * @returns any Successful Response
     * @throws ApiError
     */
    public static deleteDitUnDat(id: number): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: "DELETE",
            url: "/api/dit_un_dat/{id}",
            path: {
                id: id,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
}
