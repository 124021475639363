/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Event } from "../models/Event";
import type { EventValues } from "../models/EventValues";

import type { CancelablePromise } from "../core/CancelablePromise";
import { OpenAPI } from "../core/OpenAPI";
import { request as __request } from "../core/request";

export class EventService {
    /**
     * Get Events
     * @param id
     * @param titleFilter
     * @param start
     * @param end
     * @returns Event Successful Response
     * @throws ApiError
     */
    public static getEvents(
        id: number,
        titleFilter: string = "",
        start?: string | null,
        end?: string | null,
    ): CancelablePromise<Array<Event>> {
        return __request(OpenAPI, {
            method: "GET",
            url: "/api/calendar/{id}/event",
            path: {
                id: id,
            },
            query: {
                title_filter: titleFilter,
                start: start,
                end: end,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Create Event
     * @param id
     * @param requestBody
     * @returns Event Successful Response
     * @throws ApiError
     */
    public static createEvent(
        id: number,
        requestBody?: EventValues,
    ): CancelablePromise<Event> {
        return __request(OpenAPI, {
            method: "POST",
            url: "/api/calendar/{id}/event",
            path: {
                id: id,
            },
            body: requestBody,
            mediaType: "application/json",
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Event
     * @param id
     * @param eventId
     * @returns Event Successful Response
     * @throws ApiError
     */
    public static getEvent(
        id: number,
        eventId: number,
    ): CancelablePromise<Event> {
        return __request(OpenAPI, {
            method: "GET",
            url: "/api/calendar/{id}/event/{event_id}",
            path: {
                id: id,
                event_id: eventId,
            },
            errors: {
                404: `Not Found`,
                422: `Validation Error`,
            },
        });
    }

    /**
     * Update Event
     * @param id
     * @param eventId
     * @param requestBody
     * @returns any Successful Response
     * @throws ApiError
     */
    public static updateEvent(
        id: number,
        eventId: number,
        requestBody?: EventValues,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: "POST",
            url: "/api/calendar/{id}/event/{event_id}",
            path: {
                id: id,
                event_id: eventId,
            },
            body: requestBody,
            mediaType: "application/json",
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Delete Event
     * @param id
     * @param eventId
     * @returns any Successful Response
     * @throws ApiError
     */
    public static deleteEvent(
        id: number,
        eventId: number,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: "DELETE",
            url: "/api/calendar/{id}/event/{event_id}",
            path: {
                id: id,
                event_id: eventId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
}
