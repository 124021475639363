/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Announcement } from "../models/Announcement";
import type { AnnouncementValues } from "../models/AnnouncementValues";

import type { CancelablePromise } from "../core/CancelablePromise";
import { OpenAPI } from "../core/OpenAPI";
import { request as __request } from "../core/request";

export class AnnouncementsService {
    /**
     * List Announcements
     * @returns Announcement Successful Response
     * @throws ApiError
     */
    public static listAnnouncements(): CancelablePromise<Array<Announcement>> {
        return __request(OpenAPI, {
            method: "GET",
            url: "/api/announcements",
        });
    }

    /**
     * Create Announcement
     * @param requestBody
     * @returns Announcement Successful Response
     * @throws ApiError
     */
    public static createAnnouncement(
        requestBody?: AnnouncementValues,
    ): CancelablePromise<Announcement> {
        return __request(OpenAPI, {
            method: "POST",
            url: "/api/announcements",
            body: requestBody,
            mediaType: "application/json",
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Announcement
     * @param id
     * @returns Announcement Successful Response
     * @throws ApiError
     */
    public static getAnnouncement(id: number): CancelablePromise<Announcement> {
        return __request(OpenAPI, {
            method: "GET",
            url: "/api/announcements/{id}",
            path: {
                id: id,
            },
            errors: {
                404: `Not Found`,
                422: `Validation Error`,
            },
        });
    }

    /**
     * Update Announcement
     * @param id
     * @param requestBody
     * @returns any Successful Response
     * @throws ApiError
     */
    public static updateAnnouncement(
        id: number,
        requestBody?: AnnouncementValues,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: "POST",
            url: "/api/announcements/{id}",
            path: {
                id: id,
            },
            body: requestBody,
            mediaType: "application/json",
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Delete Announcement
     * @param id
     * @returns any Successful Response
     * @throws ApiError
     */
    public static deleteAnnouncement(id: number): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: "DELETE",
            url: "/api/announcements/{id}",
            path: {
                id: id,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
}
