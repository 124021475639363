import { nanoid } from "nanoid";
import { useState } from "react";

let updateToasts: (
  cb: (prev: React.ReactElement[]) => React.ReactElement[],
) => void = () => void 0;

export function ToastArea(): React.ReactElement {
  const [toasts, setToasts] = useState<React.ReactElement[]>([]);
  updateToasts = setToasts;

  return <div className="fixed top-24 right-24 z-20">{toasts}</div>;
}

export default {
  push: (el: React.ReactElement): void => {
    updateToasts((prev) => [
      ...prev,
      {
        ...el,
        key: nanoid(),
        onClose: () => {
          if (
            typeof el.props === "object" &&
            typeof (el.props as Record<string, any>).onClose === "function"
          ) {
            (el.props as Record<string, () => void>).onClose();
          }

          updateToasts((prev) => prev.filter((item) => item.key !== el.key));
        },
      },
    ]);
  },
};
