import { Button, InlineNotification, Stack } from "@carbon/react";
import { useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { UserService } from "../api";
import { Form, FormHandles, TextInput } from "../lib/form";

interface FormState {
  password: string;
  repeat_password: string;
}

export default function ResetPasswordForm(): React.ReactElement {
  const [busy, setBusy] = useState(false);
  const [error, setError] = useState("");
  const form = useRef<FormHandles>(null);
  const params = useParams();
  const navigate = useNavigate();
  const userId = parseInt(params.id ?? "0", 10);

  return (
    <div className="mx-auto container">
      <Form
        ref={form}
        onSubmit={(values: FormState) =>
          void (async () => {
            form.current?.setErrors({});
            let hasErrors = false;

            if (values.password.length < 6) {
              form.current?.setFieldError(
                "password",
                "Das Passwort muss aus mindestens 6 Zeichen, Ziffern oder Sonderzeichen bestehen (Emojis sind auch erlaubt)",
              );
              hasErrors = true;
            }

            if (values.password !== values.repeat_password) {
              form.current?.setFieldError(
                "repeat_password",
                "Das Passwort stimmt nicht mit dem vorherigen Passwort überein.",
              );
              hasErrors = true;
            }

            if (hasErrors) {
              console.log("has errors");
              return;
            }

            setBusy(true);

            try {
              await UserService.resetUserPassword(userId, {
                new_password: values.password,
                reset_token: params.token,
              });

              // TODO: Success message
              navigate("/");
            } catch (e) {
              setError(
                `Das Setzen des Passworts ist fehlgeschlagen: ${
                  e instanceof Error ? e.message : String(e)
                }`,
              );
            }
            setBusy(false);
          })()
        }
      >
        <Stack gap={7}>
          {error ? (
            <InlineNotification kind="error" title="Fehler">
              {error}
            </InlineNotification>
          ) : null}

          <TextInput id="password" type="password" labelText="Passwort" />
          <TextInput
            id="repeat_password"
            type="password"
            labelText="Passwort wiederholen"
          />
          <Button type="submit" disabled={busy}>
            Passwort setzen
          </Button>
        </Stack>
      </Form>
    </div>
  );
}
