import {
  Button,
  ButtonSet,
  Column,
  FlexGrid,
  InlineNotification,
  Row,
  SelectItem,
  Stack,
} from "@carbon/react";
import cx from "classnames";
import { format } from "date-fns";
import { useEffect, useRef, useState } from "react";
import useSWR from "swr";
import { AnnouncementsService } from "../api";
import {
  DatePicker,
  Form,
  FormHandles,
  Select,
  TextArea,
  TextInput,
} from "../lib/form";

interface FormState {
  id?: number;
  start: string;
  end: string;
  start_date: Date;
  start_time: string;
  end_date: Date;
  end_time: string;
  title: string;
  content: string;
  category: string;
}

export default function AnnouncementsList(): React.ReactElement {
  const [id, setID] = useState<number | null>(null);
  const [error, setError] = useState<string | null>(null);
  const [saving, setSaving] = useState(false);
  const form = useRef<FormHandles>(null);

  const { data, mutate } = useSWR("announcements", (_) =>
    AnnouncementsService.listAnnouncements(),
  );

  const [listHeight, setListHeight] = useState(window.innerHeight - 200);
  useEffect(() => {
    function listener() {
      setListHeight(window.innerHeight - 200);
    }

    window.addEventListener("resize", listener);

    return () => window.removeEventListener("resize", listener);
  }, []);

  async function save(values: FormState) {
    setSaving(true);
    setError(null);
    let hasError = false;
    form.current?.setErrors({});

    const startDate = values.start_date ?? new Date();
    if (values.start_time) {
      const m = /^([0-9]{2}):([0-9]{2})$/.exec(values.start_time);
      if (!m) {
        form.current?.setFieldError(
          "start_time",
          "Ungültiges Format: Bitte Zeit im 01:23 Format angeben.",
        );
        hasError = true;
      } else {
        const hours = parseInt(m[1], 10);
        const minutes = parseInt(m[2], 10);

        if (hours > 23) {
          form.current?.setFieldError(
            "start_time",
            "Ungültiges Format: Ein Tag hat nur 24 Stunden.",
          );
          hasError = true;
        } else {
          startDate.setHours(hours);
        }

        if (minutes > 59) {
          form.current?.setFieldError(
            "start_time",
            "Ungültiges Format: Eine Stunde hat nur 60 Minuten.",
          );
          hasError = true;
        } else {
          startDate.setMinutes(minutes);
        }

        startDate.setSeconds(0);
      }
    }

    let endDate: Date | null = null;
    if (values.end_date && values.end_time) {
      endDate = values.end_date;
      const m = /^([0-9]{2}):([0-9]{2})$/.exec(values.end_time);
      if (!m) {
        form.current?.setFieldError(
          "end_time",
          "Ungültiges Format: Bitte Zeit im 01:23 Format angeben.",
        );
        hasError = true;
      } else {
        const hours = parseInt(m[1], 10);
        const minutes = parseInt(m[2], 10);

        if (hours > 23) {
          form.current?.setFieldError(
            "end_time",
            "Ungültiges Format: Ein Tag hat nur 24 Stunden.",
          );
          hasError = true;
        } else {
          endDate.setHours(hours);
        }

        if (minutes > 59) {
          form.current?.setFieldError(
            "end_time",
            "Ungültiges Format: Eine Stunde hat nur 60 Minuten.",
          );
          hasError = true;
        } else {
          endDate.setMinutes(minutes);
        }
        endDate.setSeconds(0);
      }
    } else {
      form.current?.setFieldError(
        "end_date",
        "Sie müssen ein gültiges Datum und eine Uhrzeit angeben.",
      );
      hasError = true;
    }

    if (hasError) {
      setSaving(false);
      return;
    }

    if (id) {
      try {
        await AnnouncementsService.updateAnnouncement(id, {
          ...values,
          start: startDate.toISOString(),
          end: endDate?.toISOString() ?? "",
        });
      } catch (e) {
        const msg = e instanceof Error ? e.message : String(e);
        setError(`Konnte Meldung nicht speichern: ${msg}`);
      }
    } else {
      try {
        const result = await AnnouncementsService.createAnnouncement({
          ...values,
          start: startDate.toISOString(),
          end: endDate?.toISOString() ?? "",
        });
        setID(result.id);
      } catch (e) {
        const msg = e instanceof Error ? e.message : String(e);
        setError(`Konnte Meldung nicht anlegen: ${msg}`);
      }
    }
    setSaving(false);
    void mutate();
  }

  async function load(id: number) {
    try {
      const values = await AnnouncementsService.getAnnouncement(id);
      form.current?.setData({
        ...values,
        start_date: new Date(values.start),
        start_time: format(new Date(values.start), "HH:mm"),
        end_date: new Date(values.end),
        end_time: format(new Date(values.end), "HH:mm"),
      });
      setID(id);
    } catch (e) {
      const msg = e instanceof Error ? e.message : String(e);
      setError(`Konnte Meldung nicht laden: ${msg}`);
    }
  }

  function reset() {
    setID(null);
    form.current?.reset();
  }

  function duplicate(): void {
    setID(null);
  }

  async function remove() {
    setSaving(true);
    setError(null);

    if (id) {
      try {
        await AnnouncementsService.deleteAnnouncement(id);
        reset();
        void mutate();
      } catch (e) {
        const msg = e instanceof Error ? e.message : String(e);
        setError(`Konnte Meldung nicht löschen: ${msg}`);
      }
    }

    setSaving(false);
  }

  return (
    <FlexGrid>
      <Row>
        <Column md={2}>
          <Stack gap={2}>
            <ButtonSet>
              <Button onClick={reset}>Neu</Button>
              <Button disabled={!id} onClick={() => (id ? duplicate() : null)}>
                Duplizieren
              </Button>
            </ButtonSet>
            <div
              className="bg-white overflow-auto"
              style={{ maxHeight: listHeight }}
            >
              {data?.map((item) => (
                <div
                  onClick={() => void load(item.id)}
                  className={cx("p-2 cursor-pointer", {
                    "bg-blue-300": item.id === id,
                  })}
                >
                  {item.title}
                </div>
              ))}
            </div>
          </Stack>
        </Column>
        <Column md={6}>
          <Form
            ref={form}
            className="pt-4"
            onSubmit={(values: FormState) => void save(values)}
          >
            <Stack gap={7}>
              {error ? (
                <InlineNotification kind="error" title="Fehler">
                  {error}
                </InlineNotification>
              ) : null}

              <div className="flex">
                <DatePicker
                  id="start_date"
                  className="!flex-initial mr-4"
                  labelText="Start"
                />
                <TextInput
                  id="start_time"
                  labelText="Zeit"
                  mask={{ mask: "00:00", overwrite: true }}
                />
              </div>
              <div className="flex">
                <DatePicker
                  id="end_date"
                  className="!flex-initial mr-4"
                  labelText="Ende"
                />
                <TextInput
                  id="end_time"
                  labelText="Zeit"
                  mask={{ mask: "00:00", overwrite: true }}
                />
              </div>
              <Select id="category" labelText="Kategorie">
                <SelectItem text="Allgemein" value="Allgemein" />
                <SelectItem text="Schiff" value="Schiff" />
                <SelectItem
                  text="Startseitenmeldung"
                  value="Startseitenmeldung"
                />
                <SelectItem text="Land Unter" value="Land Unter" />
              </Select>
              <TextInput id="title" labelText="Titel" />
              <TextArea id="content" rows={10} labelText="Text" />
              <ButtonSet>
                <Button kind="primary" type="submit" disabled={saving}>
                  {saving ? "Bitte warten..." : id ? "Speichern" : "Anlegen"}
                </Button>
                <Button kind="secondary" onClick={reset}>
                  Abbrechen
                </Button>
                <Button
                  kind="danger"
                  disabled={saving || !id}
                  onClick={() => void remove()}
                >
                  {saving ? "Bitte warten..." : "Löschen"}
                </Button>
              </ButtonSet>
            </Stack>
          </Form>
        </Column>
      </Row>
    </FlexGrid>
  );
}
