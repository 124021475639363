import Hypher from "hypher";
import { FontMetrics, PDFMetrics, PdfService } from "../../api";
import { LayoutEngine, pdf_pt_per_mm } from "./layout_engine";

const fontMap: Record<string, (size: string) => string> = {};
export let weights: Record<string, Record<number, string[]>>;
export let metrics: Record<string, FontMetrics>;

export const PathPaintRule = {
    STROKE: 1,
    FILL_NONZERO: 2,
    STROKE_FILL_NONZERO: 3,
};

export const Transform = {
    translation(x: number, y: number) {
        x = ptToPX(x);
        y = ptToPX(y);
        return (ctx: CanvasRenderingContext2D) => ctx.translate(x, y);
    },
};

const pxPerMM = (() => {
    const el = document.createElement("div");
    el.style.width = "1mm";

    document.body.appendChild(el);
    const result = el.getBoundingClientRect().width;
    document.body.removeChild(el);

    return result;
})();

export function pxToMM(px: number): number {
    return px / pxPerMM;
}

export function mmToPX(mm: number): number {
    return Math.round(mm * pxPerMM);
}

export function ptToPX(pt: number): number {
    return (pt / pdf_pt_per_mm) * pxPerMM;
}

export function pxToPT(px: number): number {
    return (px / pxPerMM) * pdf_pt_per_mm;
}

let metrics_loaded = false;
let pyphen: Pyphen | null = null;
export let piktos: Record<string, [string, number, number]> = {};
const pikto_images: Record<string, ImageBitmap> = {};
export async function getLayoutEngine(): Promise<LayoutEngine> {
    if (!metrics_loaded) {
        const resp = await PdfService.getMetrics();
        weights = resp.weights;
        metrics = resp.char_widths;
        piktos = resp.piktos as Record<string, [string, number, number]>;

        for (const [url] of Object.values(piktos)) {
            void (async () => {
                const resp = await fetch("/" + url);
                pikto_images[url] = await createImageBitmap(await resp.blob());
            })();
        }

        for (const [font, fontWeights] of Object.entries(weights)) {
            for (const [weight, names] of Object.entries(fontWeights)) {
                fontMap[names[0]] = (s) => `${weight} ${s} ${font}`;
                fontMap[names[1]] = (s) => `italic ${weight} ${s} ${font}`;
            }
        }

        for (const cb of Object.values(fontMap)) {
            const el = document.createElement("div");
            el.style.font = cb("12px");
            el.style.opacity = "0";
            el.innerHTML = "#";
            document.body.appendChild(el);
            setTimeout(() => document.body.removeChild(el), 0);
        }

        pyphen = new Pyphen(resp);
        metrics_loaded = true;
    }

    return new LayoutEngine(pyphen as Pyphen, piktos);
}

export class Pyphen {
    _h: Hypher;

    constructor(patterns: PDFMetrics) {
        this._h = new Hypher(patterns);
    }

    positions(word: string): number[] {
        const parts = this._h.hyphenate(word);
        const positions = [];
        let offset = 0;
        for (const part of parts) {
            offset += part.length;
            positions.push(offset);
        }

        positions.pop();
        return positions;
    }
}

interface CanvasOp {
    type: "move" | "line";
    x: number;
    y: number;
}

class CanvasPath {
    ops: CanvasOp[] = [];
    paint_rule: number;
    auto_close = false;
    transform?: (ctx: CanvasRenderingContext2D) => void;

    constructor(
        kwargs: { paint_rule: number },
        private ctx: CanvasRenderingContext2D,
    ) {
        this.paint_rule = kwargs.paint_rule;
    }

    move_to(x: number, y: number): void {
        this.ops.push({
            type: "move",
            x: ptToPX(x),
            y: ptToPX(y),
        });
    }

    line_to(x: number, y: number): void {
        this.ops.push({
            type: "line",
            x: ptToPX(x),
            y: ptToPX(y),
        });
    }

    render() {
        if (this.transform) {
            this.ctx.save();
            this.transform(this.ctx);
        }

        for (const op of this.ops) {
            switch (op.type) {
                case "move":
                    if (this.auto_close) {
                        this.ctx.closePath();
                    }

                    this.ctx.moveTo(op.x, op.y);
                    break;
                case "line":
                    this.ctx.lineTo(op.x, op.y);
                    break;
            }
        }

        if (this.auto_close) {
            this.ctx.closePath();
        }

        switch (this.paint_rule) {
            case PathPaintRule.STROKE:
                this.ctx.stroke();
                break;
            case PathPaintRule.FILL_NONZERO:
                this.ctx.fill("nonzero");
                break;
            case PathPaintRule.STROKE_FILL_NONZERO:
                this.ctx.stroke();
                this.ctx.fill("nonzero");
                break;
        }

        if (this.transform) {
            this.ctx.restore();
        }
    }

    __enter__(): CanvasPath {
        return this;
    }

    __exit__(e?: Error): void {
        if (e) {
            return;
        }

        this.render();
    }
}

export class Canvas {
    constructor(private ctx: CanvasRenderingContext2D) {}

    new_path(kwargs: { paint_rule: number }): CanvasPath {
        return new CanvasPath(kwargs, this.ctx);
    }

    set_font(font: string, _style: string, size: number): void {
        this.ctx.font = fontMap[font](`${ptToPX(size)}px`);
    }

    set_draw_color(r: number, g: number, b: number): void {
        this.ctx.strokeStyle = `rgb(${r}, ${g}, ${b})`;
    }

    set_fill_color(r: number, g: number, b: number): void {
        this.ctx.fillStyle = `rgb(${r}, ${g}, ${b})`;
    }

    set_line_width(width: number): void {
        this.ctx.lineWidth = width;
    }

    rect(x: number, y: number, w: number, h: number): void {
        this.ctx.rect(ptToPX(x), ptToPX(y), ptToPX(w), ptToPX(h));
    }

    text(x: number, y: number, text: string): void {
        this.ctx.fillText(text, ptToPX(x), ptToPX(y));
    }

    image(
        image: CanvasImageSource,
        x: number,
        y: number,
        w: number,
        h: number,
    ): void {
        if (pikto_images[image as unknown as string]) {
            image = pikto_images[image as unknown as string];
        }

        this.ctx.drawImage(image, ptToPX(x), ptToPX(y), ptToPX(w), ptToPX(h));
    }
}
