import {
  Content,
  Header,
  HeaderGlobalAction,
  HeaderGlobalBar,
  HeaderMenuItem,
  HeaderName,
  HeaderNavigation,
  Modal,
} from "@carbon/react";
import { useState } from "react";
import { Route, Routes, useNavigate } from "react-router-dom";
import { Key, useSWRConfig } from "swr";
import LogoutIcon from "~icons/ic/sharp-logout";
import { OpenAPI } from "./api";
import { ToastArea } from "./lib/toaster";
import AnnouncementsList from "./pages/announcement-list";
import CalendarList from "./pages/calendars-list";
import Designer from "./pages/designer";
import DitUnDatList from "./pages/dit-un-dat-list";
import EventList from "./pages/event-list";
import LoginForm from "./pages/login";
import NewAccountForm from "./pages/new-account";
import PublishDestinationsList from "./pages/publish-destinations-list";
import ResetPasswordForm from "./pages/reset-password";
import UserList from "./pages/user-list";

interface RootProps {
  loginExpired: boolean;
  onLoginRenewed: () => void;
}

export default function Root(props: RootProps): React.ReactElement {
  const [loggedIn, setLoggedIn] = useState(false);
  const [isSuperuser, setSuperuser] = useState(false);
  const [canUseDitUnDat, setCanUseDitUnDat] = useState(false);
  const [canUseAnnouncements, setCanUseAnnouncements] = useState(false);
  const { mutate, cache } = useSWRConfig();
  const navigate = useNavigate();
  const clickHandler = (e: React.MouseEvent) => {
    e.preventDefault();
    navigate(new URL((e.currentTarget as HTMLAnchorElement).href).pathname);
  };

  return (
    <>
      <Header aria-label="Kopfleiste">
        <HeaderName prefix="" href="#">
          utTermine
        </HeaderName>
        {loggedIn ? (
          <>
            <HeaderNavigation aria-label="Hauptmenü">
              <HeaderMenuItem href="/" onClick={clickHandler}>
                Termine
              </HeaderMenuItem>
              {canUseDitUnDat ? (
                <HeaderMenuItem href="/dit-un-dat" onClick={clickHandler}>
                  Dit un Dat
                </HeaderMenuItem>
              ) : null}
              {canUseAnnouncements ? (
                <HeaderMenuItem href="/announcements" onClick={clickHandler}>
                  Wichtige Meldungen
                </HeaderMenuItem>
              ) : null}
              {isSuperuser ? (
                <>
                  <HeaderMenuItem href="/calendars" onClick={clickHandler}>
                    Kalender
                  </HeaderMenuItem>
                  <HeaderMenuItem
                    href="/publish_destinations"
                    onClick={clickHandler}
                  >
                    Veröffentlichungsziele
                  </HeaderMenuItem>
                  <HeaderMenuItem href="/designer" onClick={clickHandler}>
                    Designer
                  </HeaderMenuItem>
                  <HeaderMenuItem href="/users" onClick={clickHandler}>
                    Benutzer
                  </HeaderMenuItem>
                </>
              ) : null}
            </HeaderNavigation>
            <HeaderGlobalBar>
              <HeaderGlobalAction
                aria-label="Abmelden"
                tooltipAlignment="end"
                onClick={() => {
                  OpenAPI.TOKEN = "";
                  setLoggedIn(false);

                  sessionStorage.removeItem("uttermine_token");
                  sessionStorage.removeItem("uttermine_admin");

                  for (const key of (cache as Map<Key, unknown>).keys()) {
                    void mutate(key);
                  }
                }}
              >
                <LogoutIcon />
              </HeaderGlobalAction>
            </HeaderGlobalBar>
          </>
        ) : null}
      </Header>
      <ToastArea />
      <Content>
        <Routes>
          {loggedIn ? (
            <>
              <Route path="/" element={<EventList />} />
              <Route path="/dit-un-dat" element={<DitUnDatList />} />
              <Route path="/announcements" element={<AnnouncementsList />} />
              {isSuperuser ? (
                <>
                  <Route path="/users" element={<UserList />} />
                  <Route path="/calendars" element={<CalendarList />} />
                  <Route path="/designer" element={<Designer />} />
                  <Route
                    path="/publish_destinations"
                    element={<PublishDestinationsList />}
                  />
                </>
              ) : null}
            </>
          ) : (
            <>
              <Route
                path="/new_account/:id/:token/"
                element={<NewAccountForm />}
              />
              <Route
                path="/password_reset/:id/:token/"
                element={<ResetPasswordForm />}
              />
              <Route
                path="/*"
                element={
                  <LoginForm
                    useStoredLogin
                    onLoginDone={(
                      superuser,
                      canUseDitUnDat,
                      canUseAnnouncements,
                    ) => {
                      setLoggedIn(true);
                      setSuperuser(superuser);
                      setCanUseDitUnDat(canUseDitUnDat);
                      setCanUseAnnouncements(canUseAnnouncements);
                    }}
                  />
                }
              />
            </>
          )}
        </Routes>
      </Content>
      {props.loginExpired ? (
        <Modal
          open
          passiveModal
          preventCloseOnClickOutside
          modalHeading="Login abgelaufen"
        >
          <LoginForm
            onLoginDone={(superuser, canUseDitUnDat, canUseAnnouncements) => {
              setLoggedIn(true);
              setSuperuser(superuser);
              setCanUseDitUnDat(canUseDitUnDat);
              setCanUseAnnouncements(canUseAnnouncements);
              props.onLoginRenewed();
            }}
          />
        </Modal>
      ) : null}
    </>
  );
}
