/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { PublishDestination } from "../models/PublishDestination";
import type { PublishDestinationValues } from "../models/PublishDestinationValues";

import type { CancelablePromise } from "../core/CancelablePromise";
import { OpenAPI } from "../core/OpenAPI";
import { request as __request } from "../core/request";

export class DestinationService {
    /**
     * Create Publish Destination
     * @param title
     * @param description
     * @returns PublishDestination Successful Response
     * @throws ApiError
     */
    public static createPublishDestination(
        title: string,
        description: string,
    ): CancelablePromise<PublishDestination> {
        return __request(OpenAPI, {
            method: "POST",
            url: "/api/publish_destination",
            query: {
                title: title,
                description: description,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * List Publish Destinations
     * @returns PublishDestination Successful Response
     * @throws ApiError
     */
    public static listPublishDestinations(): CancelablePromise<
        Array<PublishDestination>
    > {
        return __request(OpenAPI, {
            method: "GET",
            url: "/api/publish_destination",
        });
    }

    /**
     * Get Publish Destination
     * @param id
     * @returns PublishDestination Successful Response
     * @throws ApiError
     */
    public static getPublishDestination(
        id: number,
    ): CancelablePromise<PublishDestination> {
        return __request(OpenAPI, {
            method: "GET",
            url: "/api/publish_destination/{id}",
            path: {
                id: id,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Update Publish Destination
     * @param id
     * @param requestBody
     * @returns any Successful Response
     * @throws ApiError
     */
    public static updatePublishDestination(
        id: number,
        requestBody?: PublishDestinationValues,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: "POST",
            url: "/api/publish_destination/{id}",
            path: {
                id: id,
            },
            body: requestBody,
            mediaType: "application/json",
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Delete Publish Destination
     * @param id
     * @returns any Successful Response
     * @throws ApiError
     */
    public static deletePublishDestination(id: number): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: "DELETE",
            url: "/api/publish_destination/{id}",
            path: {
                id: id,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
}
