/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Calendar } from "../models/Calendar";
import type { CalendarValues } from "../models/CalendarValues";

import type { CancelablePromise } from "../core/CancelablePromise";
import { OpenAPI } from "../core/OpenAPI";
import { request as __request } from "../core/request";

export class CalendarService {
    /**
     * List Calendars
     * @returns Calendar Successful Response
     * @throws ApiError
     */
    public static listCalendars(): CancelablePromise<Array<Calendar>> {
        return __request(OpenAPI, {
            method: "GET",
            url: "/api/calendar",
        });
    }

    /**
     * Create Calendar
     * @param requestBody
     * @returns Calendar Successful Response
     * @throws ApiError
     */
    public static createCalendar(
        requestBody?: CalendarValues,
    ): CancelablePromise<Calendar> {
        return __request(OpenAPI, {
            method: "POST",
            url: "/api/calendar",
            body: requestBody,
            mediaType: "application/json",
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Calendar
     * @param id
     * @returns Calendar Successful Response
     * @throws ApiError
     */
    public static getCalendar(id: number): CancelablePromise<Calendar> {
        return __request(OpenAPI, {
            method: "GET",
            url: "/api/calendar/{id}",
            path: {
                id: id,
            },
            errors: {
                404: `Not Found`,
                422: `Validation Error`,
            },
        });
    }

    /**
     * Update Calendar
     * @param id
     * @param requestBody
     * @returns any Successful Response
     * @throws ApiError
     */
    public static updateCalendar(
        id: number,
        requestBody?: CalendarValues,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: "POST",
            url: "/api/calendar/{id}",
            path: {
                id: id,
            },
            body: requestBody,
            mediaType: "application/json",
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Delete Calendar
     * @param id
     * @returns any Successful Response
     * @throws ApiError
     */
    public static deleteCalendar(id: number): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: "DELETE",
            url: "/api/calendar/{id}",
            path: {
                id: id,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
}
