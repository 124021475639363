let inf;
// import re
// from dataclasses import dataclass
// from enum import Enum
// from typing import Dict, List, Optional, Tuple, Union, cast
// from designer import RichTextMark
// from pyphen import Pyphen
export {
    BreakNode,
    ContentBox,
    GlueBox,
    ImageNode,
    Linebreaker,
    PenaltyBox,
    TextNode,
    inf,
};
// Fix undefined variables
import { metrics } from "./canvas-shim.ts";

const sum = (list) => list.reduce((a, b) => a + b, 0);
const re = {
    sub: (pattern, repl, haystack) =>
        haystack.replace(new RegExp(pattern, "g"), repl),
    split: (pattern, input) => input.split(new RegExp(pattern)),
};

function measure_string(text, font, size) {
    const fm = metrics[font];
    let width = 0;

    for (let i = 0; i < text.length; i++) {
        const code = text.charCodeAt(i);
        if (!fm.c[code]) {
            width += fm.d;
        } else {
            width += fm.c[code];
        }
    }

    return width * 0.001 * size;
}

/*

function measure_string(text, font, size) {
let w;
// from pdf_renderer import full_char_widths
w = full_char_widths[font];
return ((sum(text.map(c => w[ord(c)])) * 0.001) * size);
}
*/
class ImageNode {
    constructor(values = {}) {
        this.glue = [null, null];
        this.x = 0;
        Object.assign(this, values);
        this.__dict__ = this;
    }
    // width: BinOp(left=Name(id='int', ctx=Load()), op=BitOr(), right=Name(id='float', ctx=Load()))
    // height: Name(id='int', ctx=Load())
    // glue: Subscript(value=Name(id='Tuple', ctx=Load()), slice=Tuple(elts=[Subscript(value=Name(id='Optional', ctx=Load()), slice=Constant(value='GlueBox'), ctx=Load()), Subscript(value=Name(id='Optional', ctx=Load()), slice=Constant(value='GlueBox'), ctx=Load())], ctx=Load()), ctx=Load())
    // x: BinOp(left=Name(id='int', ctx=Load()), op=BitOr(), right=Name(id='float', ctx=Load()))
}
class TextNode {
    constructor(values = {}) {
        this.width = 0;
        this.x = 0;
        this.marks = null;
        Object.assign(this, values);
        this.__dict__ = this;
    }
    // text: Name(id='str', ctx=Load())
    // font: Name(id='str', ctx=Load())
    // size: Name(id='float', ctx=Load())
    // height: Name(id='float', ctx=Load())
    // width: BinOp(left=Name(id='int', ctx=Load()), op=BitOr(), right=Name(id='float', ctx=Load()))
    // x: BinOp(left=Name(id='int', ctx=Load()), op=BitOr(), right=Name(id='float', ctx=Load()))
    // marks: BinOp(left=Subscript(value=Name(id='list', ctx=Load()), slice=Name(id='RichTextMark', ctx=Load()), ctx=Load()), op=BitOr(), right=Constant(value=None))
    copy() {
        return new TextNode(this.__dict__);
    }
}
class BreakNode {
    constructor(values = {}) {
        Object.assign(this, values);
        this.__dict__ = this;
    }
}
const BoxType = {
    Glue: 1,
    Content: 2,
    Penalty: 3,
};
class Box {
    constructor(values = {}) {
        Object.assign(this, values);
        this.__dict__ = this;
    }
    // type: Name(id='BoxType', ctx=Load())
    // width: BinOp(left=Name(id='int', ctx=Load()), op=BitOr(), right=Name(id='float', ctx=Load()))
}
class GlueBox extends Box {
    constructor(values = {}) {
        super();
        Object.assign(this, values);
        this.__dict__ = this;
    }
    // stretch: Name(id='int', ctx=Load())
    // shrink: Name(id='int', ctx=Load())
}
class ContentBox extends Box {
    constructor(values = {}) {
        super();
        Object.assign(this, values);
        this.__dict__ = this;
    }
    // piece: Subscript(value=Name(id='Optional', ctx=Load()), slice=Name(id='str', ctx=Load()), ctx=Load())
    // content: Subscript(value=Name(id='Union', ctx=Load()), slice=Tuple(elts=[Name(id='ImageNode', ctx=Load()), Name(id='TextNode', ctx=Load())], ctx=Load()), ctx=Load())
}
class PenaltyBox extends Box {
    constructor(values = {}) {
        super();
        this.piece = null;
        this.content = null;
        Object.assign(this, values);
        this.__dict__ = this;
    }
    // penalty: Name(id='int', ctx=Load())
    // flagged: Name(id='int', ctx=Load())
    // piece: Subscript(value=Name(id='Optional', ctx=Load()), slice=Name(id='str', ctx=Load()), ctx=Load())
    // content: Subscript(value=Name(id='Union', ctx=Load()), slice=Tuple(elts=[Name(id='ImageNode', ctx=Load()), Name(id='TextNode', ctx=Load()), Name(id='BreakNode', ctx=Load()), Constant(value=None)], ctx=Load()), ctx=Load())
}
class Breakpoint {
    constructor(values = {}) {
        this.position = 0;
        this.line = 0;
        this.fitness = 1;
        this.total_width = 0;
        this.total_stretch = 0;
        this.total_shrink = 0;
        this.total_demerits = 0;
        this.box = null;
        this.previous = null;
        this.next = null;
        Object.assign(this, values);
        this.__dict__ = this;
    }
    // position: Name(id='int', ctx=Load())
    // line: Name(id='int', ctx=Load())
    // fitness: Name(id='int', ctx=Load())
    // total_width: BinOp(left=Name(id='int', ctx=Load()), op=BitOr(), right=Name(id='float', ctx=Load()))
    // total_stretch: Name(id='int', ctx=Load())
    // total_shrink: Name(id='int', ctx=Load())
    // total_demerits: Name(id='float', ctx=Load())
    // box: Subscript(value=Name(id='Union', ctx=Load()), slice=Tuple(elts=[Name(id='GlueBox', ctx=Load()), Name(id='ContentBox', ctx=Load()), Name(id='PenaltyBox', ctx=Load()), Constant(value=None)], ctx=Load()), ctx=Load())
    // previous: Subscript(value=Name(id='Optional', ctx=Load()), slice=Constant(value='Breakpoint'), ctx=Load())
    // next: Subscript(value=Name(id='Optional', ctx=Load()), slice=Constant(value='Breakpoint'), ctx=Load())
}
inf = 100000;
class Linebreaker {
    // _measure_cache: Subscript(value=Name(id='Dict', ctx=Load()), slice=Tuple(elts=[Name(id='str', ctx=Load()), Name(id='float', ctx=Load())], ctx=Load()), ctx=Load())
    _sum_width = 0;
    _sum_stretch = 0;
    _sum_shrink = 0;
    constructor(
        hypher,
        space_width = 4,
        space_stretch = 9,
        space_shrink = 1,
        demerit_line = 10,
        demerit_flagged = 30000,
        demerit_fitness = 10000,
        tolerance = 2,
    ) {
        this._measure_cache = {};
        this._hypher = hypher;
        this.space_width = space_width;
        this.space_stretch = space_stretch;
        this.space_shrink = space_shrink;
        this.demerit_line = demerit_line;
        this.demerit_flagged = demerit_flagged;
        this.demerit_fitness = demerit_fitness;
        this.tolerance = tolerance;
        this._force_break = false;
    }
    measure(node, text) {
        let cache, key;
        key = `${node.font};${node.size};${text}`;
        cache = this._measure_cache[key] || null;
        if (cache === null || cache === undefined) {
            cache = this._measure_cache[key] = measure_string(
                text,
                node.font,
                node.size,
            );
        }
        return cache;
    }
    justify(nodes, width, loose = 0) {
        let b,
            best,
            best_line,
            box,
            boxes,
            closest,
            dist,
            gwidth,
            hyph_width,
            last,
            last_char,
            lg,
            lines,
            lp,
            node,
            piece,
            pos,
            positions,
            prev,
            rg,
            shrink,
            stretch,
            tol,
            word,
            words;
        boxes = [];
        for (node of nodes) {
            if (node instanceof ImageNode) {
                [lg, rg] = node.glue;
                if (lg) {
                    boxes.push(lg);
                }
                boxes.push(
                    new ContentBox({
                        type: BoxType.Content,
                        width: node.width,
                        content: node,
                        piece: "",
                    }),
                );
                if (rg) {
                    boxes.push(rg);
                }
            } else if (node instanceof BreakNode) {
                boxes.push(
                    new GlueBox({
                        type: BoxType.Glue,
                        width: 0,
                        stretch: inf,
                        shrink: 0,
                    }),
                );
                boxes.push(
                    new PenaltyBox({
                        type: BoxType.Penalty,
                        width: 0,
                        penalty: -inf,
                        flagged: 0,
                        piece: "",
                        content: node,
                    }),
                );
            } else {
                words = re.split("(\\s+)", node.text);
                hyph_width = this.measure(node, "-");
                last_char = null;
                for (let idx = 0; idx < words.length; idx += 2) {
                    word = words[idx];
                    if (word.length > 5) {
                        positions = this._hypher.positions(word);
                    } else {
                        positions = [];
                    }
                    lp = 0;
                    positions.push(word.length);
                    for (let i = 0; i < positions.length; i++) {
                        pos = positions[i];
                        piece = word.slice(lp, pos);
                        lp = pos;
                        if (piece.startsWith("-")) {
                            last = null;
                            if (boxes[boxes.length - 1].type === BoxType.Glue) {
                                last = boxes.pop();
                            }
                            boxes.push(
                                new PenaltyBox({
                                    type: BoxType.Penalty,
                                    width: 0,
                                    penalty: inf,
                                    flagged: 1,
                                    piece: "",
                                    content: node,
                                }),
                            );
                            if (last !== null && last !== undefined) {
                                boxes.push(last);
                            }
                            boxes.push(
                                new ContentBox({
                                    type: BoxType.Content,
                                    width: hyph_width,
                                    piece: "-",
                                    content: node,
                                }),
                            );
                            boxes.push(
                                new PenaltyBox({
                                    type: BoxType.Penalty,
                                    width: 0,
                                    penalty: inf,
                                    flagged: 1,
                                    piece: "",
                                    content: node,
                                }),
                            );
                            piece = piece.slice(1);
                        }
                        if (piece.length < 1) {
                            continue;
                        }
                        boxes.push(
                            new ContentBox({
                                type: BoxType.Content,
                                width: this.measure(node, piece),
                                piece: piece,
                                content: node,
                            }),
                        );
                        if (i < positions.length - 1) {
                            if (piece.endsWith("-")) {
                                boxes.push(
                                    new PenaltyBox({
                                        type: BoxType.Penalty,
                                        width: 0,
                                        penalty: 30,
                                        flagged: 1,
                                        piece: "",
                                        content: node,
                                    }),
                                );
                            } else {
                                boxes.push(
                                    new PenaltyBox({
                                        type: BoxType.Penalty,
                                        width: hyph_width,
                                        penalty: 50,
                                        flagged: 1,
                                        piece: "-",
                                        content: node,
                                    }),
                                );
                            }
                        } else {
                            last_char = piece[piece.length - 1];
                        }
                    }
                    if (idx < words.length - 2) {
                        [gwidth, stretch, shrink] = [
                            this.space_width,
                            this.space_stretch,
                            this.space_shrink,
                        ];
                        if (last_char === ",") {
                            [gwidth, stretch, shrink] = [5, 4, 2];
                        } else if (last_char === ";") {
                            [gwidth, stretch, shrink] = [5, 4, 1];
                        } else if (last_char === ".") {
                            [gwidth, stretch, shrink] = [6, 6, 1];
                        }
                        boxes.push(
                            new GlueBox({
                                type: BoxType.Glue,
                                width: gwidth,
                                stretch: stretch,
                                shrink: shrink,
                            }),
                        );
                    }
                }
            }
        }
        boxes.push(
            new PenaltyBox({
                type: BoxType.Penalty,
                width: 0,
                penalty: inf,
                flagged: 0,
            }),
        );
        boxes.push(
            new GlueBox({
                type: BoxType.Glue,
                width: 0,
                stretch: inf,
                shrink: 0,
            }),
        );
        boxes.push(
            new PenaltyBox({
                type: BoxType.Penalty,
                width: 0,
                penalty: -inf,
                flagged: 0,
            }),
        );
        tol = this.tolerance;
        this._boxes = boxes;
        this._force_break = false;
        for (let attempt = 0; attempt < 10; attempt++) {
            if (attempt > 0) {
                this.tolerance = inf;
            }
            if (attempt > 1) {
                loose += 10;
            }
            if (attempt > 5) {
                this._force_break = true;
            }
            try {
                this._active = new Breakpoint();
                this._passive = null;
                this._sum_width = 0;
                this._sum_stretch = 0;
                this._sum_shrink = 0;
                for (let i = 0; i < boxes.length; i++) {
                    box = boxes[i];
                    if (box.type === BoxType.Content) {
                        this._sum_width += box.width;
                    } else if (i > 0 && box.type === BoxType.Glue) {
                        prev = boxes[i - 1];
                        if (prev.type === BoxType.Content) {
                            this._main(box, width, i, boxes);
                        }
                        this._sum_width += box.width;
                        this._sum_stretch += box.stretch;
                        this._sum_shrink += box.shrink;
                    } else if (
                        box.type === BoxType.Penalty &&
                        box.penalty !== inf
                    ) {
                        this._main(box, width, i, boxes);
                    }
                }
                break;
            } catch (e) {
                if (attempt === 9) {
                    return this._fallback(boxes, width);
                }
                console.log(e);
            }
        }
        this.tolerance = tol;
        best = this._active;
        if (best === null || best === undefined) {
            throw new Error("No active box");
        }
        b = best.next;
        while (b !== null && b !== undefined) {
            if (b.total_demerits < best.total_demerits) {
                best = b;
            }
            b = b.next;
        }
        if (loose !== 0) {
            best_line = best.line;
            b = this._active;
            closest = 0;
            while (b !== null && b !== undefined) {
                dist = b.line - best_line;
                if (
                    (loose <= dist && dist < closest) ||
                    (closest < dist && dist <= loose)
                ) {
                    closest = dist;
                    best = b;
                } else if (
                    dist === closest &&
                    b.total_demerits < best.total_demerits
                ) {
                    best = b;
                }
                b = b.next;
            }
        }
        lines = [];
        lp = boxes.length;
        pos = 0;
        while (best !== null && best !== undefined) {
            if (best.position === 0) {
                pos = 0;
            } else {
                pos = best.position + 1;
            }
            lines.splice(0, 0, boxes.slice(pos, lp));
            lp = pos;
            best = best.previous;
        }
        this._active = null;
        this._passive = null;
        return lines;
    }
    _fallback(boxes, width) {
        let box, line, lines, pending_penalty, remaining;
        lines = [];
        line = [];
        lines.push(line);
        remaining = width;
        pending_penalty = null;
        for (let i = 0; i < boxes.length; i++) {
            box = boxes[i];
            if (box instanceof ContentBox) {
                if (box.width > remaining) {
                    if (pending_penalty) {
                        line.push(pending_penalty);
                    } else {
                        console.log(
                            "WARN: Forcing break before content %d" % i,
                        );
                    }
                    line = [];
                    lines.push(line);
                    remaining = width;
                }
                line.push(box);
                remaining -= box.width;
                pending_penalty = null;
            } else if (box instanceof GlueBox) {
                if (box.width > remaining) {
                    line = [];
                    lines.push(line);
                    remaining = width;
                    pending_penalty = null;
                } else {
                    line.push(box);
                    remaining -= box.width;
                }
            } else if (box instanceof PenaltyBox) {
                if (box.penalty === -inf) {
                    line = [];
                    lines.push(line);
                    remaining = width;
                } else if (box.penalty === inf) {
                    pending_penalty = null;
                } else {
                    pending_penalty = box;
                }
            }
        }
        return lines;
    }
    _main(box, width, i, boxes) {
        let b,
            box_flag,
            break_flag,
            candidates,
            demerits,
            fitness,
            forced,
            item,
            line,
            line_shrink,
            line_stretch,
            line_width,
            min_demerits,
            new_break,
            next_b,
            prev_b,
            ratio,
            total_shrink,
            total_stretch,
            total_width;
        b = this._active;
        prev_b = null;
        candidates = [
            [inf, null],
            [inf, null],
            [inf, null],
            [inf, null],
        ];
        min_demerits = inf;
        demerits = 0;
        forced = false;
        ratio = inf;
        for (let depth = 0; depth < 100; depth++) {
            if (b === null || b === undefined) {
                break;
            }
            next_b = b.next;
            line_width = this._sum_width - b.total_width;
            if (box.type === BoxType.Penalty) {
                line_width += box.width;
            }
            if (line_width < width) {
                line_stretch = this._sum_stretch - b.total_stretch;
                if (line_stretch > 0) {
                    ratio = (width - line_width) / line_stretch;
                } else {
                    ratio = inf;
                }
            } else if (line_width > width) {
                line_shrink = this._sum_shrink - b.total_shrink;
                if (line_shrink > 0) {
                    ratio = (width - line_width) / line_shrink;
                } else {
                    ratio = inf;
                }
            } else {
                ratio = 0.0;
            }
            if (
                ratio < -1 ||
                (box.type === BoxType.Penalty && box.penalty === -inf)
            ) {
                if (prev_b === null || prev_b === undefined) {
                    this._active = next_b;
                } else {
                    prev_b.next = next_b;
                }
                ('\n                print(\n                    "de",\n                    ratio,\n                    line_width,\n                    width,\n                    b.position,\n                    b.total_demerits,\n                    b.fitness,\n                )\n                ');
                b.next = this._passive;
                this._passive = b;
            } else {
                prev_b = b;
            }
            if (ratio >= -1 && ratio <= this.tolerance) {
                if (box.type !== BoxType.Penalty || box.penalty >= 0) {
                    demerits =
                        Math.pow(
                            this.demerit_line +
                                100 * Math.pow(Math.abs(ratio), 3),
                            2,
                        ) + Math.pow(box.penalty ?? 0, 2);
                } else if (box.penalty !== inf) {
                    demerits =
                        Math.pow(
                            this.demerit_line +
                                100 * Math.pow(Math.abs(ratio), 3),
                            2,
                        ) - Math.pow(box.penalty, 2);
                } else {
                    demerits = Math.pow(
                        this.demerit_line + 100 * Math.pow(Math.abs(ratio), 3),
                        2,
                    );
                }
                box_flag = 0;
                break_flag = 0;
                if (box.type === BoxType.Penalty) {
                    box_flag = box.flagged;
                }
                if (boxes[i - 1].type === BoxType.Penalty) {
                    break_flag = boxes[i - 1].flagged;
                }
                demerits += this.demerit_flagged * box_flag * break_flag;
                if (ratio < -0.5) {
                    fitness = 0;
                } else if (ratio <= 0.5) {
                    fitness = 1;
                } else if (ratio <= 1) {
                    fitness = 2;
                } else {
                    fitness = 3;
                }
                if (Math.abs(fitness - b.fitness) > 1) {
                    demerits += this.demerit_fitness;
                }
                demerits += b.total_demerits;
                if (demerits < candidates[fitness][0]) {
                    candidates[fitness] = [demerits, b];
                    if (demerits < min_demerits) {
                        min_demerits = demerits;
                    }
                }
            }
            b = next_b;
            if (depth === 99) {
                b.next = null;
            }
        }
        if (min_demerits < inf || forced) {
            total_width = this._sum_width;
            total_stretch = this._sum_stretch;
            total_shrink = this._sum_shrink;
            for (item of boxes.slice(i)) {
                if (item.type === BoxType.Content) {
                    break;
                }
                if (item.type === BoxType.Glue) {
                    total_width += item.width;
                    total_stretch += item.stretch;
                    total_shrink += item.shrink;
                } else if (item.penalty === -inf && item !== box) {
                    break;
                }
            }
            for (let fitness = 0; fitness < 4; fitness++) {
                if (
                    (candidates[fitness][0] <=
                        min_demerits + this.demerit_fitness ||
                        forced) &&
                    candidates[fitness][1] !== null &&
                    candidates[fitness][1] !== undefined
                ) {
                    new_break = new Breakpoint({
                        position: i,
                        box: box,
                        line: candidates[fitness][1].line + 1,
                        fitness: fitness,
                        total_width: total_width,
                        total_stretch: total_stretch,
                        total_shrink: total_shrink,
                        total_demerits: candidates[fitness][0],
                        previous: candidates[fitness][1],
                        next: b,
                    });
                    if (prev_b === null || prev_b === undefined) {
                        this._active = new_break;
                    } else {
                        prev_b.next = new_break;
                    }
                    prev_b = new_break;
                }
            }
        }
        if (
            (this._active === null || this._active === undefined) &&
            min_demerits === inf &&
            ratio < -1
        ) {
            total_width = this._sum_width;
            total_stretch = this._sum_stretch;
            total_shrink = this._sum_shrink;
            for (item of boxes.slice(i)) {
                if (item.type === BoxType.Content) {
                    break;
                }
                if (item.type === BoxType.Glue) {
                    total_width += item.width;
                    total_stretch += item.stretch;
                    total_shrink += item.shrink;
                } else if (item.penalty === -inf && item !== box) {
                    break;
                }
            }
            if (candidates[3][1] === null || candidates[3][1] === undefined) {
                line = 0;
            } else {
                line = candidates[3][1].line;
            }
            new_break = new Breakpoint({
                position: i,
                box: box,
                line: line + 1,
                fitness: 3,
                total_width: total_width,
                total_stretch: total_stretch,
                total_shrink: total_shrink,
                total_demerits: candidates[3][0],
                previous: this._passive,
                next: b,
            });
            this._active = new_break;
        }
        if (this._active === null || this._active === undefined) {
            throw new Error(`No possible solution (${min_demerits}, %f)`);
        }
    }
    layout(lines, width) {
        let box,
            cbox,
            content,
            layout,
            lbox,
            line,
            line_height,
            line_shrink,
            line_stretch,
            line_width,
            node,
            ratio,
            result,
            tbox,
            text_queue,
            x;
        layout = [];
        for (line of lines) {
            x = 0;
            line_height = 0;
            line_width = 0;
            line_stretch = 0;
            line_shrink = 0;
            while (
                line.length > 0 &&
                line[line.length - 1].type === BoxType.Glue
            ) {
                line.pop();
            }
            if (line.length < 1) {
                layout.push([]);
                continue;
            }
            for (box of line) {
                if (box.type === BoxType.Content) {
                    line_width += box.width;
                    if (box.content.height > line_height) {
                        line_height = box.content.height;
                    }
                } else if (box.type === BoxType.Glue) {
                    line_width += box.width;
                    line_stretch += box.stretch;
                    line_shrink += box.shrink;
                }
            }
            if (line[line.length - 1].type === BoxType.Penalty) {
                line_width += line[line.length - 1].width;
            }
            if (false) {
            } else if (line_width < width && line_stretch > 0) {
                ratio = (line_width - width) / line_stretch;
                for (box of line) {
                    if (box.type === BoxType.Glue) {
                        box.width -= box.stretch * ratio;
                    }
                }
            } else if (line_width > width && line_shrink > 0) {
                ratio = (line_width - width) / line_shrink;
                for (box of line) {
                    if (box.type === BoxType.Glue) {
                        box.width -= box.shrink * ratio;
                        if (box.width < 0) {
                            box.width = 0;
                        }
                    }
                }
            }
            result = [];
            text_queue = [];
            for (box of line) {
                if (box.type === BoxType.Penalty) {
                    continue;
                }
                if (
                    box.type === BoxType.Content &&
                    box.content instanceof TextNode
                ) {
                    if (
                        text_queue.length < 1 ||
                        (text_queue[0].content.font === box.content.font &&
                            text_queue[0].content.size === box.content.size &&
                            text_queue[0].content.height === box.content.height)
                    ) {
                        text_queue.push(box);
                        continue;
                    }
                }
                if (text_queue.length > 0) {
                    tbox = text_queue[0].content.copy();
                    tbox.text = text_queue.map((q) => q.piece).join("");
                    tbox.width = sum(text_queue.map((q) => q.width));
                    tbox.x = x;
                    result.push(tbox);
                    x += tbox.width;
                    text_queue = [];
                }
                if (box.type === BoxType.Content) {
                    cbox = box;
                    if (cbox.content instanceof TextNode) {
                        text_queue.push(cbox);
                        continue;
                    }
                    content = cbox.content;
                    content.x = x;
                    result.push(content);
                    x += box.width;
                } else if (box.type === BoxType.Glue) {
                    if (x > 0) {
                        x += box.width;
                    }
                }
            }
            if (text_queue.length > 0) {
                tbox = text_queue[0].content.copy();
                tbox.text = text_queue.map((q) => q.piece).join("");
                tbox.width = sum(text_queue.map((q) => q.width));
                tbox.x = x;
                result.push(tbox);
                x += tbox.width;
            }
            if (
                line[line.length - 1].type === BoxType.Penalty &&
                line[line.length - 1].width > 0
            ) {
                lbox = line[line.length - 1];
                if (lbox.content instanceof TextNode) {
                    node = new TextNode({
                        text:
                            lbox.piece !== null && lbox.piece !== undefined
                                ? lbox.piece
                                : "",
                        font: lbox.content.font,
                        size: lbox.content.size,
                        height: lbox.content.height,
                        width: lbox.width,
                    });
                    node.x = x;
                    result.push(node);
                }
            }
            layout.push(result);
        }
        return layout;
    }
}
