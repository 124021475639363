import { Button, ButtonSet, InlineNotification, Stack } from "@carbon/react";
import { useEffect, useRef, useState } from "react";
import { DefaultService, OpenAPI, UserService } from "../api";
import { Form, FormHandles, TextInput } from "../lib/form";

interface FormState {
  username: string;
  password: string;
  email: string;
}

interface LoginFormProps {
  useStoredLogin?: boolean;
  onLoginDone: (
    superuser: boolean,
    canUseDitUnDat: boolean,
    canUseAnnouncements: boolean,
  ) => void;
}

export default function LoginForm(props: LoginFormProps): React.ReactElement {
  const [busy, setBusy] = useState(false);
  const [error, setError] = useState("");
  const form = useRef<FormHandles>(null);
  const [isPasswordReset, setPasswordReset] = useState(false);
  const [isResetOk, setResetOk] = useState(false);

  useEffect(() => {
    if (props.useStoredLogin) {
      const sessToken = sessionStorage.getItem("uttermine_token");
      if (sessToken) {
        OpenAPI.TOKEN = sessToken;
        props.onLoginDone(
          sessionStorage.getItem("uttermine_admin") === "true",
          sessionStorage.getItem("uttermine_canUseDitUnDat") === "true",
          sessionStorage.getItem("uttermine_canUseAnnouncements") === "true",
        );
      }
    }
  }, [props]);

  return (
    <div className="mx-auto container">
      <Form
        ref={form}
        onSubmit={(values: FormState) =>
          void (async () => {
            setBusy(true);
            setError("");
            form.current?.setFieldValue("password", "");

            if (isPasswordReset) {
              try {
                await UserService.requestPasswordReset(values.email);
                setResetOk(true);
              } catch (e) {
                setError(
                  `Das Passwort zurücksetzen ist fehlgeschlagen: ${
                    e instanceof Error ? e.message : String(e)
                  }`,
                );
              }
              setBusy(false);
              return;
            }

            try {
              const result = await DefaultService.loginForToken(values);
              if (result.access_token) {
                OpenAPI.TOKEN = result.access_token;

                sessionStorage.setItem("uttermine_token", result.access_token);
                sessionStorage.setItem(
                  "uttermine_admin",
                  result.superuser ? "true" : "false",
                );
                sessionStorage.setItem(
                  "uttermine_canUseDitUnDat",
                  result.can_use_dit_un_dat ? "true" : "false",
                );
                sessionStorage.setItem(
                  "uttermine_canUseAnnouncements",
                  result.can_use_announcements ? "true" : "false",
                );

                props.onLoginDone(
                  result.superuser,
                  result.can_use_dit_un_dat,
                  result.can_use_announcements,
                );
                return;
              }
            } catch (e) {
              setError(
                `Anmeldung ist fehlgeschlagen: ${
                  e instanceof Error ? e.message : String(e)
                }`,
              );
            }
            setBusy(false);
          })()
        }
      >
        <Stack gap={7}>
          {error ? (
            <InlineNotification kind="error" title="Fehler">
              {error}
            </InlineNotification>
          ) : null}

          {isResetOk ? (
            <InlineNotification kind="success" title="Passwort zurückgesetzt">
              Ihr Passwort wurde zurückgesetzt. Bitte kontrollieren Sie Ihr
              E-Mail Postfach für die nächsten Schritte.
            </InlineNotification>
          ) : null}

          {!isPasswordReset ? (
            <>
              <TextInput id="username" required labelText="Benutzername" />
              <TextInput
                id="password"
                type="password"
                required
                labelText="Passwort"
              />
              <ButtonSet>
                <Button type="submit" disabled={busy}>
                  Anmelden
                </Button>
                <Button
                  type="button"
                  kind="secondary"
                  onClick={() => setPasswordReset(true)}
                >
                  Passwort zurücksetzen
                </Button>
              </ButtonSet>
            </>
          ) : (
            <>
              <TextInput id="email" type="email" required labelText="E-Mail" />
              <ButtonSet>
                <Button type="submit" disabled={busy}>
                  Passwort zurücksetzen
                </Button>
                <Button
                  type="button"
                  kind="secondary"
                  onClick={() => setPasswordReset(false)}
                >
                  Abbrechen
                </Button>
              </ButtonSet>
            </>
          )}
        </Stack>
      </Form>
    </div>
  );
}
