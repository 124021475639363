import {
  Button,
  ButtonSet,
  DatePicker as CarbonDatePicker,
  TextInput as CarbonTextInput,
  Column,
  DatePickerInput,
  Dropdown,
  FlexGrid,
  InlineNotification,
  Row,
  Select,
  SelectItem,
  Stack,
  StructuredListBody,
  StructuredListCell,
  StructuredListHead,
  StructuredListRow,
  StructuredListWrapper,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  ToastNotification,
} from "@carbon/react";
import { useEffect, useRef, useState } from "react";
import useSWR, { mutate } from "swr";
import { CalendarService, DesignerService, OpenAPI } from "../api";
import { Form, FormHandles, TextInput } from "../lib/form";
import toaster from "../lib/toaster";
import { Canvas, CanvasItem } from "./designer/canvas";

interface FormValues {
  title: string;
  width: string;
  height: string;
  blocks: CanvasItem[];
}

const pxPerMM = (() => {
  const el = document.createElement("div");
  el.style.width = "1mm";

  document.body.appendChild(el);
  const result = el.getBoundingClientRect().width;
  document.body.removeChild(el);

  return result;
})();

function pxToMM(px: number): number {
  return px / pxPerMM;
}

function mmToPX(mm: number): number {
  return Math.round(mm * pxPerMM);
}

function GenForm() {
  const [rangeFilter, setRangeFilter] = useState(() => {
    const today = new Date();
    return [
      new Date(today.getFullYear(), today.getMonth(), today.getDate() - 14),
      new Date(today.getFullYear(), today.getMonth(), today.getDate()),
    ];
  });
  const [title, setTitle] = useState(
    () =>
      `Buddelbreef ${rangeFilter[0].getDate()}.${rangeFilter[0].getMonth()}.${rangeFilter[0].getFullYear()} - ${rangeFilter[1].getDate()}.${rangeFilter[1].getMonth()}.${rangeFilter[1].getFullYear()}`,
  );
  const [calId, setCalId] = useState<number | null>(null);
  const [pdfURL, setPDFURL] = useState("about:blank");

  const { data: calendars } = useSWR("event-list-calendars", async (_) =>
    (await CalendarService.listCalendars()).map((cal) => ({
      value: cal.id,
      label: cal.title,
    })),
  );

  useEffect(() => {
    if (
      (calId === null || calId === undefined) &&
      calendars &&
      calendars.length > 0
    ) {
      setCalId(calendars[0].value);
    }
  }, [calId, calendars]);

  useEffect(() => {
    if (calId === null || !rangeFilter[0] || !rangeFilter[1]) {
      setPDFURL("about:blank");
      return;
    }

    void (async () => {
      const url = `/api/buddelbreef/preview?cal_id=${calId}&start=${rangeFilter[0].toISOString()}&end=${rangeFilter[1].toISOString()}`;
      const response = await fetch(url, {
        headers: { Authorization: `Bearer ${(OpenAPI.TOKEN as string) ?? ""}` },
      });
      setPDFURL(URL.createObjectURL(await response.blob()));
    })();
  }, [calId, rangeFilter]);

  return (
    <div className="flex flex-col gap-2">
      <Select
        id="selected_calendar"
        labelText="Kalender"
        onChange={(e) => setCalId(parseInt(e.target.value, 10))}
      >
        {calendars?.map((item) => (
          <SelectItem key={item.value} text={item.label} value={item.value} />
        ))}
      </Select>
      <CarbonDatePicker
        dateFormat="d.m.Y"
        datePickerType="range"
        value={rangeFilter ?? undefined}
        onChange={(value) => {
          setRangeFilter([value[0], value[1]]);
        }}
      >
        <DatePickerInput
          id={"search-date-start"}
          pattern="\d{2}\.\d{2}\.\d{4}"
          placeholder="dd.mm.YYYY"
          labelText="Start"
          type="text"
        />
        <DatePickerInput
          id={"search-date-end"}
          pattern="\d{2}\.\d{2}\.\d{4}"
          placeholder="dd.mm.YYYY"
          labelText="Ende"
          type="text"
        />
      </CarbonDatePicker>
      <CarbonTextInput
        id="gen-title"
        labelText="Titel"
        value={title}
        onChange={(e) => setTitle(e.currentTarget.value)}
      />
      <ButtonSet>
        <Button
          kind="primary"
          size="md"
          onClick={async (e) => {
            e.preventDefault();
            if (calId) {
              await DesignerService.makeIssue(
                title,
                calId,
                rangeFilter[0].toISOString(),
                rangeFilter[1].toISOString(),
              );
              toaster.push(
                <ToastNotification
                  kind="success"
                  title="Erfolg"
                  caption="Die Seiten wurden erfolgreich erzeugt."
                  timeout={10000}
                />,
              );
              void mutate("designer-pages");
            } else {
              toaster.push(
                <ToastNotification
                  kind="error"
                  title="Fehler"
                  caption="Es wurde kein Kalender ausgewählt."
                  timeout={10000}
                />,
              );
            }
          }}
        >
          Seiten generieren
        </Button>
      </ButtonSet>
      <div>Vorschau</div>
      <iframe src={pdfURL} style={{ height: "500px" }} />
    </div>
  );
}

export default function Designer(): React.ReactElement {
  const [genOpen, setGenOpen] = useState(false);

  const form = useRef<FormHandles>(null);
  const [pageID, setPageID] = useState<number | null>(null);
  const [saveError, setSaveError] = useState<string | null>(null);
  const [canvasSize, setCanvasSize] = useState<{
    width: number;
    height: number;
  }>({ width: 0, height: 0 });
  const [pdfURL, setPDFurl] = useState<string | null>(null);

  const { data: pages, mutate } = useSWR("designer-pages", () =>
    DesignerService.listDesignerPages(),
  );
  const { data: pageData } = useSWR(
    pageID ? ["designer-page", pageID] : null,
    async ([_, id]) => {
      const page = await DesignerService.getDesignerPage(id);
      return {
        ...page,
        blocks: page.blocks.map((block) => ({
          ...block,
          top: mmToPX(block.top),
          left: mmToPX(block.left),
          width: mmToPX(block.width),
          height: mmToPX(block.height),
        })),
      };
    },
  );

  function reset() {
    if (form.current && pageData) {
      form.current?.setData(pageData);
      updateCanvasSize();
      void fetchPDF();
    }
  }

  // We don't need a dependency on updateCanvasSize() (it'd cause the effect to trigger on each render and it's not necessary).
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(reset, [pageData]);

  async function save(data: FormValues): Promise<void> {
    setSaveError(null);

    const values = {
      title: data.title,
      width: parseInt(data.width, 10),
      height: parseInt(data.height, 10),
      blocks: data.blocks.map((block) => ({
        ...block,
        top: Math.round(pxToMM(block.top)),
        left: Math.round(pxToMM(block.left)),
        width: Math.round(pxToMM(block.width)),
        height: Math.round(pxToMM(block.height)),
      })),
    };

    if (pageID) {
      try {
        await DesignerService.updateDesignerPage(pageID, values);
      } catch (e: unknown) {
        setSaveError(
          `Beim Speichern der Seite ist ein Fehler aufgetreten: ${
            e instanceof Error ? e.message : String(e)
          }`,
        );
        return;
      }
    } else {
      try {
        setPageID(await DesignerService.createDesignerPage(values));
      } catch (e: unknown) {
        setSaveError(
          `Beim Anlegen der Seite ist ein Fehler aufgetreten: ${
            e instanceof Error ? e.message : String(e)
          }`,
        );
        return;
      }
    }

    void mutate();
    void fetchPDF();
  }

  async function remove() {
    if (!pageID || !form.current) {
      return;
    }

    try {
      await DesignerService.deleteDesignerPage(pageID);
    } catch (e: unknown) {
      setSaveError(
        `Beim Löschen der Seite ist ein Fehler aufgetreten: ${
          e instanceof Error ? e.message : String(e)
        }`,
      );
      return;
    }

    form.current.reset();
    void mutate();
  }

  function updateCanvasSize() {
    const width = mmToPX(
      parseInt(form.current?.getFieldValue("width") as string, 10),
    );
    const height = mmToPX(
      parseInt(form.current?.getFieldValue("height") as string, 10),
    );

    if (
      !isNaN(width) &&
      !isNaN(height) &&
      (width !== canvasSize.width || height !== canvasSize.height)
    ) {
      setCanvasSize({ width, height });
    }
  }

  async function fetchPDF() {
    if (pageID === null) {
      setPDFurl(null);
      return;
    }

    const response = await fetch(`/api/designer_page/${pageID}/pdf`, {
      headers: { Authorization: `Bearer ${(OpenAPI.TOKEN as string) ?? ""}` },
    });
    setPDFurl(URL.createObjectURL(await response.blob()));
  }

  return (
    <FlexGrid className="mt-8">
      <Row>
        <Column md={2}>
          <ButtonSet>
            <Button kind="ghost" size="md" onClick={() => setGenOpen(true)}>
              Buddelbreef
            </Button>
          </ButtonSet>
          <ButtonSet>
            <Button
              kind="primary"
              size="md"
              onClick={() => form.current?.reset()}
            >
              Neu
            </Button>
            <Button
              kind="ghost"
              size="md"
              onClick={() => {
                setPageID(null);
                reset();
              }}
            >
              Duplizieren
            </Button>
          </ButtonSet>
          <StructuredListWrapper selection isCondensed>
            <StructuredListHead>
              <StructuredListRow head>
                <StructuredListCell head>Name</StructuredListCell>
              </StructuredListRow>
            </StructuredListHead>
            <StructuredListBody>
              {pages?.map((item) => (
                <StructuredListRow key={item.id}>
                  <StructuredListCell
                    onClick={() => {
                      setPageID(item.id);
                      setGenOpen(false);
                    }}
                  >
                    {item.title}
                  </StructuredListCell>
                </StructuredListRow>
              ))}
            </StructuredListBody>
          </StructuredListWrapper>
        </Column>
        <Column md={6}>
          {genOpen ? (
            <GenForm />
          ) : (
            <Form ref={form} onSubmit={(data: FormValues) => void save(data)}>
              {saveError ? (
                <InlineNotification
                  kind="error"
                  title="Fehler"
                  onClose={() => (setSaveError(null), true)}
                >
                  {saveError}
                </InlineNotification>
              ) : null}
              <Tabs onChange={updateCanvasSize}>
                <TabList aria-label="Formularabschnitte">
                  <Tab>Metadaten</Tab>
                  <Tab>Inhalt</Tab>
                  <Tab>PDF-Vorschau</Tab>
                </TabList>
                <TabPanels>
                  <TabPanel>
                    <Stack gap={7}>
                      <TextInput id="title" labelText="Titel" />
                      <Dropdown
                        id="preset"
                        label="Größe"
                        items={["A5", "A6"]}
                        selectedItem={null}
                        onChange={(e) => {
                          switch (e.selectedItem) {
                            case "A5":
                              form.current?.setFieldValue("width", "148");
                              form.current?.setFieldValue("height", "210");
                              break;
                            case "A6":
                              form.current?.setFieldValue("width", "105");
                              form.current?.setFieldValue("height", "148");
                              break;
                          }
                        }}
                      />
                      <Stack gap={6} orientation="horizontal">
                        <TextInput
                          id="width"
                          type="number"
                          labelText="Breite"
                          helperText="mm"
                        />
                        <TextInput
                          id="height"
                          type="number"
                          labelText="Höhe"
                          helperText="mm"
                        />
                      </Stack>
                      <ButtonSet>
                        <Button type="submit">Speichern</Button>
                        <Button
                          kind="secondary"
                          onClick={() => {
                            setPageID(null);
                            form.current?.reset();
                          }}
                        >
                          Abbrechen
                        </Button>
                        <Button kind="danger" onClick={() => void remove()}>
                          Löschen
                        </Button>
                      </ButtonSet>
                    </Stack>
                  </TabPanel>
                  <TabPanel>
                    <Canvas name="blocks" {...canvasSize} />
                  </TabPanel>
                  <TabPanel>
                    {pdfURL ? (
                      <iframe
                        src={pdfURL}
                        style={{
                          border: 0,
                          width: "100%",
                          height: "100%",
                          minHeight: "500px",
                        }}
                      />
                    ) : null}
                  </TabPanel>
                </TabPanels>
              </Tabs>
            </Form>
          )}
        </Column>
      </Row>
    </FlexGrid>
  );
}
