import {
  Button,
  ButtonSet,
  DatePicker as CarbonDatePicker,
  Column,
  DatePickerInput,
  FlexGrid,
  InlineNotification,
  Row,
  Select,
  SelectItem,
  TextInput as SimpleTextInput,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
  ToastNotification,
} from "@carbon/react";
import { useField } from "@unform/core";
import cx from "classnames";
import { format } from "date-fns";
import { useEffect, useRef, useState } from "react";
import useSWR from "swr";
import { CalendarService, DestinationService, EventService } from "../api";
import {
  Checkbox,
  CheckboxList,
  DatePicker,
  Form,
  FormHandles,
  TextArea,
  TextInput,
} from "../lib/form";
import toaster from "../lib/toaster";

const piktos = [
  "festzelt",
  "film",
  "gummistiefel",
  "halligkirche",
  "hw-nw",
  "musik",
  "sa-su",
  "segelboot",
  "smiley",
  "wuerfel",
  "stiefel",
  "trachtensommer",
  "theater",
  "warft",
  "fuesse",
  "wuerstchen",
];

interface EventFormState {
  id?: number;
  start_date: Date | null;
  start_time: string;
  end_date: Date | null;
  end_time: string;
  title: string;
  subtitle: string;
  logo: number | undefined;
  description: string;
  destinations: string[];
  draft: boolean;
}

function useLaggyValue<T>(value: T, delay = 300): T {
  const [result, setResult] = useState(value);
  const timer = useRef<number | null>(null);

  useEffect(() => {
    if (timer.current !== null) {
      clearTimeout(timer.current);
    }
    timer.current = setTimeout(() => setResult(value), delay);
  }, [value, delay]);

  return result;
}

function PiktoInput(props: { id: string }): React.ReactElement {
  const { fieldName, defaultValue, registerField, error } = useField(props.id);
  const [selected, setSelected] = useState<number | undefined>(
    defaultValue as number,
  );

  registerField({
    name: fieldName,
    getValue() {
      return selected;
    },
    setValue(_, value) {
      setSelected(value);
    },
    clearValue(_, value) {
      setSelected(value);
    },
  });

  return (
    <div>
      <div
        className={cx(
          "inline-block w-8 m-2 bg-white cursor-pointer border",
          selected === undefined ? "border-black" : "border-white",
        )}
        onClick={(e) => {
          e.preventDefault();
          setSelected(undefined);
        }}
      >
        X
      </div>
      {piktos.map((name, idx) => (
        <img
          key={idx}
          className={cx(
            "inline-block w-8 m-2 cursor-pointer bg-white border",
            idx === selected ? "border-black" : "border-white",
          )}
          src={`/static/piktos/${name}.jpg`}
          onClick={(e) => {
            e.preventDefault();
            setSelected(idx);
          }}
        />
      ))}
    </div>
  );
}

export default function EventList(): React.ReactElement {
  const [id, setID] = useState<number | null>(null);
  const [rangeFilter, setRangeFilter] = useState<[Date, Date] | null>(() => {
    const rangeStart = new Date();

    const rangeEnd = new Date();
    rangeEnd.setFullYear(rangeEnd.getFullYear() + 1);
    return [rangeStart, rangeEnd];
  });
  const form = useRef<FormHandles>(null);

  const [selectedCalendar, setSelectedCalendar] = useState<number | null>(null);
  const [saving, setSaving] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const { data: calendars } = useSWR("event-list-calendars", async (_) =>
    (await CalendarService.listCalendars()).map((cal) => ({
      value: cal.id,
      label: cal.title,
    })),
  );
  const { data: destinations } = useSWR(
    "publish-destinations-options",
    async (_) => {
      const dests = await DestinationService.listPublishDestinations();
      const options: Record<number, string> = {};
      for (const item of dests) {
        options[item.id] = item.title;
      }

      return options;
    },
  );
  const [titleFilter, setTitleFilter] = useState("");
  console.log("cal", selectedCalendar);
  const { data, mutate } = useSWR(
    ["events", selectedCalendar, rangeFilter, useLaggyValue(titleFilter)],
    ([_, calID, range, title_filter]) =>
      calID === null
        ? []
        : EventService.getEvents(
            calID,
            title_filter,
            range ? range[0].toISOString() : undefined,
            range ? range[1].toISOString() : undefined,
          ),
  );

  useEffect(() => {
    if (
      (selectedCalendar === null || selectedCalendar === undefined) &&
      calendars &&
      calendars.length > 0
    ) {
      setSelectedCalendar(calendars[0].value);
    }
  }, [selectedCalendar, calendars]);

  /*const lastEnd = useRef<Date | null>(null);
  useEffect(() => {
    if (
      eventValues.end_date === null &&
      eventValues.start_date !== null &&
      lastEnd.current === null
    ) {
      lastEnd.current = eventValues.start_date;
      setEventValues((prev) => ({
        ...prev,
        end_date: new Date(prev.start_date!),
      }));
    } else {
      lastEnd.current = eventValues.end_date ?? null;
    }
  }, [eventValues.end_date, eventValues.start_date]);*/

  const [tableHeight, setTableHeight] = useState(window.innerHeight - 200);
  useEffect(() => {
    function listener() {
      setTableHeight(window.innerHeight - 200);
    }

    window.addEventListener("resize", listener);
    return () => window.removeEventListener("resize", listener);
  }, []);

  function newEvent() {
    form.current?.reset();
    setID(null);
  }

  async function loadEvent(id: number): Promise<void> {
    try {
      if (!selectedCalendar) {
        return;
      }
      const result = await EventService.getEvent(selectedCalendar, id);
      //lastEnd.current = new Date();
      setID(id);
      form.current?.setData({
        id: result.id,
        start_date: new Date(result.start),
        start_time: format(new Date(result.start), "HH:mm"),
        end_date: result.end ? new Date(result.end) : null,
        end_time: result.end ? format(new Date(result.end), "HH:mm") : "",
        description: result.description,
        subtitle: result.subtitle,
        logo: result.logo,
        title: result.title,
        destinations: result.destinations?.map(String) ?? [],
        draft: result.draft ?? false,
      });
    } catch (e) {
      const msg = e instanceof Error ? e.message : String(e);
      setError(`Konnte Termin nicht laden: ${msg}`);
    }
  }

  function reset() {
    if (!id) {
      newEvent();
    } else {
      void loadEvent(id);
    }
  }

  async function duplicate(id: number): Promise<void> {
    await loadEvent(id);
    setID(null);
    form.current?.clearField("start_date");
    form.current?.clearField("start_time");
    form.current?.clearField("end_date");
    form.current?.clearField("end_time");
  }

  async function save(eventValues: EventFormState) {
    setSaving(true);
    setError(null);
    form.current?.setErrors({});
    let hasError = false;

    const startDate = eventValues.start_date ?? new Date();
    if (eventValues.start_time) {
      const m = /^([0-9]{2}):([0-9]{2})$/.exec(eventValues.start_time);
      if (!m) {
        form.current?.setFieldError(
          "start_time",
          "Ungültiges Format: Bitte Zeit im 01:23 Format angeben.",
        );
        hasError = true;
      } else {
        const hours = parseInt(m[1], 10);
        const minutes = parseInt(m[2], 10);

        if (hours > 23) {
          form.current?.setFieldError(
            "start_time",
            "Ungültiges Format: Ein Tag hat nur 24 Stunden.",
          );
          hasError = true;
        } else {
          startDate.setHours(hours);
        }

        if (minutes > 59) {
          form.current?.setFieldError(
            "start_time",
            "Ungültiges Format: Eine Stunde hat nur 60 Minuten.",
          );
          hasError = true;
        } else {
          startDate.setMinutes(minutes);
        }

        startDate.setSeconds(0);
      }
    }

    let endDate: Date | null = null;
    if (eventValues.end_date && eventValues.end_time) {
      endDate = eventValues.end_date;
      const m = /^([0-9]{2}):([0-9]{2})$/.exec(eventValues.end_time);
      if (!m) {
        form.current?.setFieldError(
          "end_time",
          "Ungültiges Format: Bitte Zeit im 01:23 Format angeben.",
        );
        hasError = true;
      } else {
        const hours = parseInt(m[1], 10);
        const minutes = parseInt(m[2], 10);

        if (hours > 23) {
          form.current?.setFieldError(
            "end_time",
            "Ungültiges Format: Ein Tag hat nur 24 Stunden.",
          );
          hasError = true;
        } else {
          endDate.setHours(hours);
        }

        if (minutes > 59) {
          form.current?.setFieldError(
            "end_time",
            "Ungültiges Format: Eine Stunde hat nur 60 Minuten.",
          );
          hasError = true;
        } else {
          endDate.setMinutes(minutes);
        }
        endDate.setSeconds(0);
      }
    }

    if (!eventValues.destinations) {
    	eventValues.destinations = [];
    }

    if (!selectedCalendar) {
      setError(
        `Konnte Termin nicht speichern, da kein Kalender ausgewählt ist.`,
      );
    } else if (hasError) {
      setError("Nicht alle Felder wurden korrekt ausgefüllt.");
      setSaving(false);
      return;
    } else if (id) {
      try {
        await EventService.updateEvent(selectedCalendar, id, {
          ...eventValues,
          destinations: eventValues.destinations.map((d) => parseInt(d, 10)),
          start: startDate.toISOString(),
          end: endDate?.toISOString(),
        });

        toaster.push(
          <ToastNotification
            kind="success"
            title="Erfolg"
            caption="Der Termin wurde erfolgreich gespeichert."
            timeout={10000}
          />,
        );
      } catch (e) {
        const msg = e instanceof Error ? e.message : String(e);
        setError(`Konnte Termin nicht speichern: ${msg}`);
      }
    } else {
      try {
        await EventService.createEvent(selectedCalendar, {
          ...eventValues,
          destinations: eventValues.destinations.map((d) => parseInt(d, 10)),
          start: startDate.toISOString(),
          end: endDate?.toISOString(),
        });

        toaster.push(
          <ToastNotification
            kind="success"
            title="Erfolg"
            caption="Der Termin wurde erfolgreich angelegt."
            timeout={10000}
          />,
        );
        newEvent();
      } catch (e) {
        const msg = e instanceof Error ? e.message : String(e);
        setError(`Konnte Termin nicht anlegen: ${msg}`);
      }
    }

    setSaving(false);
    void mutate();
  }

  async function remove() {
    setSaving(true);
    setError(null);

    if (!selectedCalendar) {
      setError(
        `Konnte Termin nicht speichern, da kein Kalender ausgewählt ist.`,
      );
    } else if (id) {
      try {
        await EventService.deleteEvent(selectedCalendar, id);
        newEvent();
        void mutate();
      } catch (e) {
        const msg = e instanceof Error ? e.message : String(e);
        setError(`Konnte Termin nicht löschen: ${msg}`);
      }
    }

    setSaving(false);
  }

  return (
    <FlexGrid>
      <Row>
        <Column md={2}>
          <Stack gap={5} className="pt-4">
            <Select
              id="selected_calendar"
              labelText="Kalender"
              onChange={(e) =>
                setSelectedCalendar(parseInt(e.target.value, 10))
              }
            >
              {calendars?.map((item) => (
                <SelectItem
                  key={item.value}
                  text={item.label}
                  value={item.value}
                />
              ))}
            </Select>
            <CarbonDatePicker
              dateFormat="d.m.Y"
              datePickerType="range"
              value={rangeFilter ?? undefined}
              onChange={(value) => {
                setRangeFilter([value[0], value[1]]);
              }}
            >
              <DatePickerInput
                id={"search-date-start"}
                pattern="\d{2}\.\d{2}\.\d{4}"
                placeholder="dd.mm.YYYY"
                labelText="Start"
                type="text"
              />
              <DatePickerInput
                id={"search-date-end"}
                pattern="\d{2}\.\d{2}\.\d{4}"
                placeholder="dd.mm.YYYY"
                labelText="Ende"
                type="text"
              />
            </CarbonDatePicker>
            <ButtonSet>
              <Button onClick={newEvent}>Neu</Button>
              <Button
                disabled={!id}
                onClick={() => (id ? void duplicate(id) : null)}
              >
                Duplizieren
              </Button>
            </ButtonSet>
            <SimpleTextInput
              id="title_filter"
              value={titleFilter}
              onChange={(e) => setTitleFilter(e.target.value)}
              labelText="Suche"
              placeholder="Suche..."
            />
            <Table style={{ height: tableHeight }} stickyHeader>
              <TableHead>
                <TableRow>
                  <TableHeader id="start">Datum</TableHeader>
                  <TableHeader id="title">Titel</TableHeader>
                </TableRow>
              </TableHead>
              <TableBody>
                {data?.map((row) => {
                  const cls = cx({
                    "!bg-blue-300": id === row.id,
                    "!bg-gray-300": id !== row.id && row.draft,
                  });
                  return (
                    <TableRow key={row.id}>
                      <TableCell
                        className={cls}
                        onClick={() => void loadEvent(row.id)}
                      >
                        {format(new Date(row.start), "dd.MM.yyyy HH:mm")}
                      </TableCell>
                      <TableCell
                        className={cls}
                        onClick={() => void loadEvent(row.id)}
                      >
                        {row.title}
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </Stack>
        </Column>
        <Column md={6}>
          <Form
            ref={form}
            className="pt-4"
            onSubmit={(values: EventFormState) => void save(values)}
          >
            <Stack gap={5}>
              {error ? (
                <InlineNotification kind="error" title="Fehler">
                  {error}
                </InlineNotification>
              ) : null}

              <div className="flex">
                <DatePicker
                  id="start_date"
                  className="!flex-initial mr-4"
                  labelText="Start"
                />
                <TextInput
                  id="start_time"
                  labelText="Zeit"
                  mask={{ mask: "00:00", overwrite: true }}
                />
              </div>
              <div className="flex">
                <DatePicker
                  id="end_date"
                  className="!flex-initial mr-4"
                  labelText="Ende"
                />
                <TextInput
                  id="end_time"
                  labelText="Zeit"
                  mask={{ mask: "00:00", overwrite: true }}
                />
              </div>
              <Checkbox id="draft" labelText="Entwurf" />
              <TextInput id="title" labelText="Titel" />
              <PiktoInput id="logo" />
              <TextInput id="subtitle" labelText="Untertitel" />
              <TextArea id="description" rows={10} labelText="Text" />
              {(destinations && Array.from(Object.keys(destinations)).length > 0) ? <CheckboxList
                id="destinations"
                options={destinations ?? {}}
                labelText="Veröffentlichungsziele"
              /> : null}

              {/* TODO logo */}
              <ButtonSet>
                <Button kind="primary" type="submit" disabled={saving}>
                  {saving ? "Bitte warten..." : "Speichern"}
                </Button>
                <Button kind="secondary" onClick={reset}>
                  Abbrechen
                </Button>
                <Button
                  kind="danger"
                  disabled={saving || !id}
                  onClick={() => void remove()}
                >
                  {saving ? "Bitte warten..." : "Löschen"}
                </Button>
              </ButtonSet>
            </Stack>
          </Form>
        </Column>
      </Row>
    </FlexGrid>
  );
}
