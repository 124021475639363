/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Body_login_for_token } from "../models/Body_login_for_token";
import type { HealthStatus } from "../models/HealthStatus";
import type { Token } from "../models/Token";

import type { CancelablePromise } from "../core/CancelablePromise";
import { OpenAPI } from "../core/OpenAPI";
import { request as __request } from "../core/request";

export class DefaultService {
    /**
     * Login For Token
     * @param formData
     * @returns Token Successful Response
     * @throws ApiError
     */
    public static loginForToken(
        formData: Body_login_for_token,
    ): CancelablePromise<Token> {
        return __request(OpenAPI, {
            method: "POST",
            url: "/api/token",
            formData: formData,
            mediaType: "application/x-www-form-urlencoded",
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Health Check
     * @returns HealthStatus Successful Response
     * @throws ApiError
     */
    public static healthCheck(): CancelablePromise<HealthStatus> {
        return __request(OpenAPI, {
            method: "GET",
            url: "/health",
        });
    }

    /**
     * Get Favicon
     * @returns any Successful Response
     * @throws ApiError
     */
    public static getFavicon(): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: "GET",
            url: "/favicon.ico",
        });
    }

    /**
     * Send Index
     * @returns string Successful Response
     * @throws ApiError
     */
    public static sendIndex(): CancelablePromise<string> {
        return __request(OpenAPI, {
            method: "GET",
            url: "/password_reset/{user}/{token}/",
        });
    }

    /**
     * Send Index
     * @returns string Successful Response
     * @throws ApiError
     */
    public static sendIndex1(): CancelablePromise<string> {
        return __request(OpenAPI, {
            method: "GET",
            url: "/new_account/{user}/{token}/",
        });
    }

    /**
     * Send Index
     * @returns string Successful Response
     * @throws ApiError
     */
    public static sendIndex2(): CancelablePromise<string> {
        return __request(OpenAPI, {
            method: "GET",
            url: "/designer",
        });
    }

    /**
     * Send Index
     * @returns string Successful Response
     * @throws ApiError
     */
    public static sendIndex3(): CancelablePromise<string> {
        return __request(OpenAPI, {
            method: "GET",
            url: "/announcements",
        });
    }

    /**
     * Send Index
     * @returns string Successful Response
     * @throws ApiError
     */
    public static sendIndex4(): CancelablePromise<string> {
        return __request(OpenAPI, {
            method: "GET",
            url: "/dit-un-dat",
        });
    }

    /**
     * Send Index
     * @returns string Successful Response
     * @throws ApiError
     */
    public static sendIndex5(): CancelablePromise<string> {
        return __request(OpenAPI, {
            method: "GET",
            url: "/users",
        });
    }

    /**
     * Send Index
     * @returns string Successful Response
     * @throws ApiError
     */
    public static sendIndex6(): CancelablePromise<string> {
        return __request(OpenAPI, {
            method: "GET",
            url: "/event_types",
        });
    }

    /**
     * Send Index
     * @returns string Successful Response
     * @throws ApiError
     */
    public static sendIndex7(): CancelablePromise<string> {
        return __request(OpenAPI, {
            method: "GET",
            url: "/calendars",
        });
    }

    /**
     * Send Index
     * @returns string Successful Response
     * @throws ApiError
     */
    public static sendIndex8(): CancelablePromise<string> {
        return __request(OpenAPI, {
            method: "GET",
            url: "/",
        });
    }
}
