/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Body_upload_image } from "../models/Body_upload_image";
import type { CheckResult } from "../models/CheckResult";
import type { UploadedImage } from "../models/UploadedImage";

import type { CancelablePromise } from "../core/CancelablePromise";
import { OpenAPI } from "../core/OpenAPI";
import { request as __request } from "../core/request";

export class ImageService {
    /**
     * Check Image
     * @param hash
     * @returns CheckResult Successful Response
     * @throws ApiError
     */
    public static checkImage(hash: string): CancelablePromise<CheckResult> {
        return __request(OpenAPI, {
            method: "GET",
            url: "/api/image/check",
            query: {
                hash: hash,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Upload Image
     * @param formData
     * @returns UploadedImage Successful Response
     * @throws ApiError
     */
    public static uploadImage(
        formData: Body_upload_image,
    ): CancelablePromise<UploadedImage> {
        return __request(OpenAPI, {
            method: "POST",
            url: "/api/image/upload",
            formData: formData,
            mediaType: "multipart/form-data",
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Retrieve Image
     * @param id
     * @returns any Successful Response
     * @throws ApiError
     */
    public static retrieveImage(id: number): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: "GET",
            url: "/api/image/{id}/image",
            path: {
                id: id,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
}
