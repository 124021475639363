import {
  Button,
  ButtonSet,
  Column,
  FlexGrid,
  InlineNotification,
  Row,
  TextInput as SimpleTextInput,
  Stack,
} from "@carbon/react";
import cx from "classnames";
import { useEffect, useRef, useState } from "react";
import { IProps, List, arrayMove } from "react-movable";
import useSWR from "swr";
import { DitUnDat, DitUnDatService } from "../api";
import { Checkbox, Form, FormHandles, TextArea, TextInput } from "../lib/form";

const _he_el = document.createElement("div");
function he(input: string): string {
  _he_el.innerText = input;
  return _he_el.innerHTML;
}

class PatchedList extends List<DitUnDat> {
  constructor(props: IProps<DitUnDat>) {
    super(props);

    const original_finishDrop = this.finishDrop;
    this.finishDrop = () => {
      const result = original_finishDrop.apply(this, []);

      if (this.afterIndex === -2) {
        this.props.onChange({
          newIndex: this.state.itemDragged,
          oldIndex: this.state.itemDragged,
          targetRect: new DOMRect(),
        });
      }

      return result;
    };
  }
}

interface FormState {
  id?: number;
  slug: string;
  title: string;
  subtitle: string;
  content: string;
  visible: boolean;
  in_buddelbreef: boolean;
  in_gaestemappe: boolean;
}

export default function DitUnDatList(): React.ReactElement {
  const [id, setID] = useState<number | null>(null);
  const [error, setError] = useState<string | null>(null);
  const [saving, setSaving] = useState(false);
  const [titleFilter, setTitleFilter] = useState("");
  const form = useRef<FormHandles>(null);

  const { data, mutate } = useSWR("dit-un-dat", (_) =>
    DitUnDatService.listDitUnDat(),
  );

  const [listHeight, setListHeight] = useState(window.innerHeight - 200);
  useEffect(() => {
    function listener() {
      setListHeight(window.innerHeight - 200);
    }

    window.addEventListener("resize", listener);

    return () => window.removeEventListener("resize", listener);
  }, []);

  async function save(values: FormState) {
    setSaving(true);
    setError(null);

    if (id) {
      try {
        await DitUnDatService.updateDitUnDat(id, values);
      } catch (e) {
        const msg = e instanceof Error ? e.message : String(e);
        setError(`Konnte Dit un Dat nicht speichern: ${msg}`);
      }
    } else {
      try {
        const result = await DitUnDatService.createDitUnDat(values);
        setID(result.id);
      } catch (e) {
        const msg = e instanceof Error ? e.message : String(e);
        setError(`Konnte Dit un Dat nicht anlegen: ${msg}`);
      }
    }
    setSaving(false);
    void mutate();
  }

  async function load(id: number) {
    try {
      form.current?.setData(await DitUnDatService.getDitUnDat(id));
      setID(id);
    } catch (e) {
      const msg = e instanceof Error ? e.message : String(e);
      setError(`Konnte Dit un Dat nicht laden: ${msg}`);
    }
  }

  function reset() {
    setID(null);
    form.current?.reset();
  }

  function duplicate(): void {
    setID(null);
  }

  async function remove() {
    setSaving(true);
    setError(null);

    if (id) {
      try {
        await DitUnDatService.deleteDitUnDat(id);
        reset();
        void mutate();
      } catch (e) {
        const msg = e instanceof Error ? e.message : String(e);
        setError(`Konnte Dit un Dat nicht löschen: ${msg}`);
      }
    }

    setSaving(false);
  }

  return (
    <FlexGrid>
      <Row>
        <Column md={2}>
          <Stack gap={2}>
            <ButtonSet>
              <Button onClick={reset}>Neu</Button>
              <Button disabled={!id} onClick={() => (id ? duplicate() : null)}>
                Duplizieren
              </Button>
            </ButtonSet>
            <SimpleTextInput
              id="title_filter"
              labelText="Suche"
              value={titleFilter}
              onChange={(e) => setTitleFilter(e.target.value)}
              placeholder="Suche..."
            />
            <PatchedList
              lockVertically
              values={data ?? []}
              onChange={({ oldIndex, newIndex }) => {
                if (oldIndex === newIndex && data) {
                  void load(data[oldIndex].id);
                  return;
                }

                void (async () => {
                  await mutate(
                    DitUnDatService.moveDitUnDat({
                      old_position: data[oldIndex].order,
                      new_position: data[newIndex].order,
                    }),
                    {
                      optimisticData: arrayMove(data ?? [], oldIndex, newIndex),
                      rollbackOnError: true,
                    },
                  );
                })();
              }}
              renderList={({ children, props }) => (
                <div
                  {...props}
                  className="bg-white overflow-auto"
                  style={{ maxHeight: listHeight }}
                >
                  {children}
                </div>
              )}
              renderItem={({ value, props, isDragged }) => (
                <div
                  {...props}
                  onClick={() => void load(value.id)}
                  className={cx("p-2 cursor-pointer", {
                    "bg-blue-300": value.id === id || isDragged,
                  })}
                >
                  {titleFilter === "" ? (
                    value.title
                  ) : (
                    <span
                      dangerouslySetInnerHTML={{
                        __html: he(value.title).replace(
                          new RegExp(titleFilter, "i"),
                          (m) =>
                            '<span class="bg-red-300">' + he(m) + "</span>",
                        ),
                      }}
                    />
                  )}
                </div>
              )}
            />
          </Stack>
        </Column>
        <Column md={6}>
          <Form
            ref={form}
            className="pt-4"
            onSubmit={(values: FormState) => void save(values)}
          >
            <Stack gap={7}>
              {error ? (
                <InlineNotification kind="error" title="Fehler">
                  {error}
                </InlineNotification>
              ) : null}

              <TextInput id="slug" labelText="Code" />
              <Stack gap={2} orientation="horizontal">
                <Checkbox id="visible" labelText="Sichtbar" />
                <Checkbox id="in_buddelbreef" labelText="Im Buddelbreef" />
                <Checkbox id="in_gaestemappe" labelText="In der Gästemappe" />
              </Stack>
              <TextInput id="title" labelText="Titel" />
              <TextInput id="subtitle" labelText="Untertitel" />
              <TextArea id="content" rows={10} labelText="Text" />
              <ButtonSet>
                <Button kind="primary" type="submit" disabled={saving}>
                  {saving ? "Bitte warten..." : id ? "Speichern" : "Anlegen"}
                </Button>
                <Button kind="secondary" onClick={reset}>
                  Abbrechen
                </Button>
                <Button
                  kind="danger"
                  disabled={saving || !id}
                  onClick={() => void remove()}
                >
                  {saving ? "Bitte warten..." : "Löschen"}
                </Button>
              </ButtonSet>
            </Stack>
          </Form>
        </Column>
      </Row>
    </FlexGrid>
  );
}
