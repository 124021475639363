/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Body_reset_user_password } from "../models/Body_reset_user_password";
import type { CreateUserPayload } from "../models/CreateUserPayload";
import type { User } from "../models/User";
import type { UserValues } from "../models/UserValues";

import type { CancelablePromise } from "../core/CancelablePromise";
import { OpenAPI } from "../core/OpenAPI";
import { request as __request } from "../core/request";

export class UserService {
    /**
     * Get Users
     * The user list doesn't contain information on the user's calendars. To get this information, query the individual users.
     * @returns User Successful Response
     * @throws ApiError
     */
    public static getUsers(): CancelablePromise<Array<User>> {
        return __request(OpenAPI, {
            method: "GET",
            url: "/api/user",
        });
    }

    /**
     * Create User
     * @param requestBody
     * @returns User Successful Response
     * @throws ApiError
     */
    public static createUser(
        requestBody?: CreateUserPayload,
    ): CancelablePromise<User> {
        return __request(OpenAPI, {
            method: "POST",
            url: "/api/user",
            body: requestBody,
            mediaType: "application/json",
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get User
     * @param id
     * @returns User Successful Response
     * @throws ApiError
     */
    public static getUser(id: number): CancelablePromise<User> {
        return __request(OpenAPI, {
            method: "GET",
            url: "/api/user/{id}",
            path: {
                id: id,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Update User
     * @param id
     * @param requestBody
     * @returns any Successful Response
     * @throws ApiError
     */
    public static updateUser(
        id: number,
        requestBody?: UserValues,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: "POST",
            url: "/api/user/{id}",
            path: {
                id: id,
            },
            body: requestBody,
            mediaType: "application/json",
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Delete User
     * @param id
     * @returns any Successful Response
     * @throws ApiError
     */
    public static deleteUser(id: number): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: "DELETE",
            url: "/api/user/{id}",
            path: {
                id: id,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Update User Password
     * @param id
     * @param requestBody
     * @returns any Successful Response
     * @throws ApiError
     */
    public static updateUserPassword(
        id: number,
        requestBody?: string,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: "POST",
            url: "/api/user/{id}/password",
            path: {
                id: id,
            },
            body: requestBody,
            mediaType: "application/json",
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Reset User Password
     * @param id
     * @param requestBody
     * @returns any Successful Response
     * @throws ApiError
     */
    public static resetUserPassword(
        id: number,
        requestBody?: Body_reset_user_password,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: "POST",
            url: "/api/user/{id}/reset_password",
            path: {
                id: id,
            },
            body: requestBody,
            mediaType: "application/json",
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Request Password Reset
     * @param email
     * @returns any Successful Response
     * @throws ApiError
     */
    public static requestPasswordReset(email: string): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: "POST",
            url: "/api/request_reset/{email}",
            path: {
                email: email,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
}
