/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { MarkSpec, NodeSpec, Schema } from "prosemirror-model";
import { addListNodes } from "prosemirror-schema-list";
import { piktos } from "../pages/designer/canvas-shim";

const basicSchema = new Schema({
    nodes: {
        /// NodeSpec The top level document node.
        doc: {
            content: "block+",
        } as NodeSpec,

        /// A plain paragraph textblock. Represented in the DOM
        /// as a `<p>` element.
        paragraph: {
            content: "inline*",
            group: "block",
            attrs: {
                family: { default: "Adelbrook" },
                size: { default: "12pt" },
            },
            parseDOM: [{ tag: "p" }],
            toDOM(node) {
                const { family, size } = node.attrs;
                return [
                    "p",
                    {
                        style: `font-family: "${String(
                            family,
                        )}"; font-size: ${String(size)};`,
                    },
                    0,
                ];
            },
        } as NodeSpec,

        /// A heading textblock, with a `level` attribute that
        /// should hold the number 1 to 6. Parsed and serialized as `<h1>` to
        /// `<h6>` elements.
        heading: {
            attrs: { level: { default: 1 } },
            content: "inline*",
            group: "block",
            defining: true,
            parseDOM: [
                { tag: "h1", attrs: { level: 1 } },
                { tag: "h2", attrs: { level: 2 } },
                { tag: "h3", attrs: { level: 3 } },
                { tag: "h4", attrs: { level: 4 } },
                { tag: "h5", attrs: { level: 5 } },
                { tag: "h6", attrs: { level: 6 } },
            ],
            toDOM(node) {
                return [`h${Number(node.attrs.level)}`, 0];
            },
        } as NodeSpec,

        /// The text node.
        text: {
            group: "inline",
        } as NodeSpec,

        /// An inline image (`<img>`) node. Supports `src`,
        /// `alt`, and `href` attributes. The latter two default to the empty
        /// string.
        image: {
            inline: true,
            attrs: {
                src: { default: null },
                alt: { default: null },
                title: { default: null },
                pikto: { default: null },
            },
            group: "inline",
            draggable: true,
            parseDOM: [
                {
                    tag: "img[src]",
                    getAttrs(dom_: Node | string) {
                        const dom = dom_ as unknown as HTMLImageElement;
                        return {
                            src: dom.getAttribute("src"),
                            title: dom.getAttribute("title"),
                            alt: dom.getAttribute("alt"),
                        };
                    },
                },
            ],
            toDOM(node) {
                const { src, alt, title, pikto } = node.attrs;
                return [
                    "img",
                    {
                        src: piktos[pikto] ? `/${piktos[pikto][0]}` : src,
                        alt,
                        title,
                        width: piktos[pikto] ? `${piktos[pikto][1]}mm` : "auto",
                        height: piktos[pikto]
                            ? `${piktos[pikto][2]}mm`
                            : "auto",
                    },
                ];
            },
        } as NodeSpec,

        /// A hard line break, represented in the DOM as `<br>`.
        hard_break: {
            inline: true,
            group: "inline",
            selectable: false,
            parseDOM: [{ tag: "br" }],
            toDOM() {
                return ["br"];
            },
        } as NodeSpec,
    },
});

export default new Schema({
    nodes: addListNodes(basicSchema.spec.nodes, "paragraph block*", "block"),
    marks: {
        /// A link. Has `href` and `title` attributes. `title`
        /// defaults to the empty string. Rendered and parsed as an `<a>`
        /// element.
        link: {
            attrs: {
                href: {},
                title: { default: null },
            },
            inclusive: false,
            parseDOM: [
                {
                    tag: "a[href]",
                    getAttrs(dom_: Node | string) {
                        const dom = dom_ as unknown as HTMLAnchorElement;
                        return {
                            href: dom.getAttribute("href"),
                            title: dom.getAttribute("title"),
                        };
                    },
                },
            ],
            toDOM(node) {
                const { href, title } = node.attrs;
                return ["a", { href, title }, 0];
            },
        } as MarkSpec,

        /// An emphasis mark. Rendered as an `<em>` element. Has parse rules
        /// that also match `<i>` and `font-style: italic`.
        em: {
            parseDOM: [
                { tag: "i" },
                { tag: "em" },
                { style: "font-style=italic" },
            ],
            toDOM() {
                return ["em", 0];
            },
        } as MarkSpec,

        /// A strong mark. Rendered as `<strong>`, parse rules also match
        /// `<b>` and `font-weight: bold`.
        strong: {
            parseDOM: [
                { tag: "strong" },
                // This works around a Google Docs misbehavior where
                // pasted content will be inexplicably wrapped in `<b>`
                // tags with a font-weight normal.
                {
                    tag: "b",
                    getAttrs: (node: HTMLElement) =>
                        node.style.fontWeight !== "normal" && null,
                },
                {
                    style: "font-weight",
                    getAttrs: (value: string) =>
                        /^(bold(er)?|[5-9]\d{2,})$/.test(value) && null,
                },
            ],
            toDOM() {
                return ["strong", 0];
            },
        } as MarkSpec,

        style: {
            attrs: {
                family: { default: "Adelbrook" },
                size: { default: "12pt" },
            },
            parseDOM: [
                {
                    style: "font-family",
                    getAttrs: (value: string) => ({ family: value }),
                },
                {
                    style: "font-size",
                    getAttrs: (value: string) =>
                        value.endsWith("pt") ? value : null,
                },
            ],
            toDOM(node) {
                const { family, size } = node.attrs;
                return [
                    "span",
                    {
                        style: `font-family: "${String(
                            family,
                        )}"; font-size: ${String(size)};`,
                    },
                    0,
                ];
            },
        } as MarkSpec,
    },
});
