import {
  Button,
  ButtonSet,
  Column,
  FlexGrid,
  Row,
  Stack,
  StructuredListBody,
  StructuredListCell,
  StructuredListHead,
  StructuredListRow,
  StructuredListWrapper,
  ToastNotification,
} from "@carbon/react";
import { useRef, useState } from "react";
import useSWR from "swr";
import { CalendarService, DestinationService } from "../api";
import { CheckboxList, Form, FormHandles, TextInput } from "../lib/form";
import toaster from "../lib/toaster";

interface CalendarFormState {
  id?: number;
  url_id: string;
  title: string;
  destinations: number[];
}

export default function CalendarsList(): React.ReactElement {
  const [id, setID] = useState<number | null>(null);
  const [saving, setSaving] = useState(false);
  const form = useRef<FormHandles>(null);

  const { data, mutate } = useSWR("calendars", (_) =>
    CalendarService.listCalendars(),
  );
  const { data: destData } = useSWR("publish-destinations", (_) =>
    DestinationService.listPublishDestinations(),
  );

  const destOptions: Record<string, string> = {};
  if (destData) {
    for (const opt of destData) {
      destOptions[opt.id] = opt.title;
    }
  }

  async function save(values: CalendarFormState) {
    setSaving(true);
    if (values.id) {
      try {
        await CalendarService.updateCalendar(values.id, values);
      } catch (e) {
        const msg = e instanceof Error ? e.message : String(e);
        toaster.push(
          <ToastNotification kind="error" title="Fehler">
            Konnte Kalender nicht speichern: {msg}
          </ToastNotification>,
        );
      }
    } else {
      try {
        const result = await CalendarService.createCalendar(values);
        setID(result.id);
        form.current?.setFieldValue("id", result.id);
        form.current?.setFieldValue(
          "url_id",
          `${location.protocol}//${location.host}/api/cms/${result.url_id}`,
        );
      } catch (e) {
        const msg = e instanceof Error ? e.message : String(e);
        toaster.push(
          <ToastNotification kind="error" title="Fehler">
            Konnte Kalender nicht anlegen: {msg}
          </ToastNotification>,
        );
      }
    }
    setSaving(false);
    void mutate();
  }

  function reset() {
    form.current?.reset();
    setID(null);
  }

  async function remove() {
    setSaving(true);
    if (id) {
      try {
        await CalendarService.deleteCalendar(id);
        reset();
        void mutate();
      } catch (e) {
        const msg = e instanceof Error ? e.message : String(e);
        toaster.push(
          <ToastNotification kind="error" title="Fehler">
            Konnte Kalender nicht löschen: {msg}
          </ToastNotification>,
        );
      }
    }

    setSaving(false);
  }

  return (
    <FlexGrid className="mt-8">
      <Row>
        <Column md={2}>
          <StructuredListWrapper selection isCondensed>
            <StructuredListHead>
              <StructuredListRow head>
                <StructuredListCell head>Name</StructuredListCell>
              </StructuredListRow>
            </StructuredListHead>
            <StructuredListBody>
              {data?.map((row) => (
                <StructuredListRow key={row.id}>
                  <StructuredListCell
                    onClick={() => {
                      form.current?.setData(row);
                      setID(row.id);
                      form.current?.setFieldValue(
                        "url_id",
                        `${location.protocol}//${location.host}/api/cms/${row.url_id}`,
                      );
                    }}
                  >
                    {row.title}
                  </StructuredListCell>
                </StructuredListRow>
              ))}
            </StructuredListBody>
          </StructuredListWrapper>
        </Column>
        <Column md={6}>
          <Form
            ref={form}
            onSubmit={(values: CalendarFormState) => void save(values)}
          >
            <Stack gap={7}>
              <TextInput id="title" labelText="Name" />
              <TextInput id="url_id" labelText="Kalender-URL" readOnly />
              <CheckboxList
                id="destinations"
                labelText="Veröffentlichungsziele"
                options={destOptions}
              />
              <ButtonSet>
                <Button kind="primary" type="submit" disabled={saving}>
                  {saving ? "Bitte warten..." : id ? "Speichern" : "Anlegen"}
                </Button>
                <Button kind="secondary" onClick={reset}>
                  Abbrechen
                </Button>
                <Button
                  kind="danger"
                  disabled={saving || !id}
                  onClick={() => void remove()}
                >
                  {saving ? "Bitte warten..." : "Löschen"}
                </Button>
              </ButtonSet>
            </Stack>
          </Form>
        </Column>
      </Row>
    </FlexGrid>
  );
}
