import {
  Button,
  ButtonSet,
  Column,
  FlexGrid,
  InlineNotification,
  Row,
  Stack,
  StructuredListBody,
  StructuredListCell,
  StructuredListHead,
  StructuredListRow,
  StructuredListWrapper,
} from "@carbon/react";
import { useRef, useState } from "react";
import useSWR from "swr";
import IconChecked from "~icons/ic/round-check-box";
import IconUnchecked from "~icons/ic/round-check-box-outline-blank";
import { CalendarService, UserService } from "../api";
import {
  Checkbox,
  CheckboxList,
  Form,
  FormHandles,
  TextInput,
} from "../lib/form";

interface UserFormState {
  id?: number;
  username: string;
  email: string;
  superuser: boolean;
  password: string;
  confirm_password: string;
  calendars: string[];
}

export default function UserList(): React.ReactElement {
  const form = useRef<FormHandles>(null);
  const [id, setID] = useState<number | null>(null);
  const [saving, setSaving] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const { data: calendars } = useSWR("calendar-options", async (_) => {
    const cals = await CalendarService.listCalendars();
    const options: Record<string, string> = {};
    for (const item of cals) {
      options[item.id] = item.title;
    }

    return options;
  });
  const { data, mutate } = useSWR(
    "users",
    async (_) => await UserService.getUsers(),
  );

  async function loadUser(id: number) {
    reset();
    setError(null);

    try {
      const result = await UserService.getUser(id);
      setID(id);
      form.current?.setData({
        ...result,
        calendars: result.calendars.map(String),
        password: "",
        confirm_password: "",
      } as UserFormState);
    } catch (e) {
      const msg = e instanceof Error ? e.message : String(e);
      setError(`Konnte Benutzer nicht laden: ${msg}`);
    }
  }

  async function save(values: UserFormState) {
    setSaving(true);
    setError(null);

    if (id) {
      try {
        await UserService.updateUser(id, {
          ...values,
          calendars: values.calendars.map((v) => parseInt(v, 10)),
        });
        if (values.password !== "") {
          await UserService.updateUserPassword(id, values.password);
        }

        form.current?.setFieldValue("password", "");
        form.current?.setFieldValue("confirm_password", "");
      } catch (e) {
        const msg = e instanceof Error ? e.message : String(e);
        setError(`Konnte Benutzer nicht speichern: ${msg}`);
      }
    } else {
      try {
        const result = await UserService.createUser({
          ...values,
          calendars: values.calendars.map((v) => parseInt(v, 10)),
        });
        setID(result.id);
      } catch (e) {
        const msg = e instanceof Error ? e.message : String(e);
        setError(`Konnte Benutzer nicht anlegen: ${msg}`);
      }
    }

    setSaving(false);
    void mutate();
  }

  function reset() {
    setID(null);
    form.current?.reset();
  }

  async function remove() {
    setSaving(true);
    setError(null);

    if (id) {
      try {
        await UserService.deleteUser(id);
        reset();
        setID(null);
        void mutate();
      } catch (e) {
        const msg = e instanceof Error ? e.message : String(e);
        setError(`Konnte Benutzer nicht löschen: ${msg}`);
      }
    }

    setSaving(false);
  }

  return (
    <FlexGrid>
      <Row>
        <Column md={2}>
          <StructuredListWrapper>
            <StructuredListHead>
              <StructuredListRow head>
                <StructuredListCell head>Benutzername</StructuredListCell>
                <StructuredListCell head>Admin</StructuredListCell>
              </StructuredListRow>
            </StructuredListHead>
            <StructuredListBody>
              {data?.map((row) => (
                <StructuredListRow key={row.id}>
                  <StructuredListCell onClick={() => void loadUser(row.id)}>
                    {row.username}
                  </StructuredListCell>
                  <StructuredListCell onClick={() => void loadUser(row.id)}>
                    {row.superuser ? <IconChecked /> : <IconUnchecked />}
                  </StructuredListCell>
                </StructuredListRow>
              ))}
            </StructuredListBody>
          </StructuredListWrapper>
        </Column>
        <Column md={6}>
          <Form
            ref={form}
            onSubmit={(values: UserFormState) => void save(values)}
          >
            <Stack gap={7}>
              {error ? (
                <InlineNotification kind="error" title="Fehler">
                  {error}
                </InlineNotification>
              ) : null}

              <TextInput id="username" labelText="Benutzername" />
              <TextInput id="email" labelText="E-Mail" />
              <Checkbox id="superuser" labelText="Administrator" />
              <TextInput id="password" type="password" labelText="Passwort" />
              <TextInput
                id="confirm_password"
                type="password"
                labelText="Passwort bestätigen"
              />
              <CheckboxList
                id="calendars"
                options={calendars ?? {}}
                labelText="Sichtbare Kalender"
              />

              <ButtonSet>
                <Button kind="primary" type="submit" disabled={saving}>
                  {saving ? "Bitte warten..." : id ? "Speichern" : "Anlegen"}
                </Button>
                <Button kind="secondary" onClick={reset}>
                  Abbrechen
                </Button>
                <Button
                  kind="danger"
                  disabled={saving || !id}
                  onClick={() => void remove()}
                >
                  {saving ? "Bitte warten..." : "Löschen"}
                </Button>
              </ButtonSet>
            </Stack>
          </Form>
        </Column>
      </Row>
    </FlexGrid>
  );
}
